import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ViewProfileViewModel } from "src/app/viewModels/view-profile.viewmodel";
import { ViewProfileUseCase } from "src/app/useCases/view-profile.usecase";
import { EditProfileUseCase } from "src/app/useCases/edit-profile.usecase";
import { touchAllFormFields } from "src/app/utils/validation";

@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.scss"],
})
export class ViewProfileComponent implements OnInit {
  userProfileForm: FormGroup;
  user: ViewProfileViewModel;
  email: string;

  constructor(
    private viewProfileUseCase: ViewProfileUseCase,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: Router,
    private editProfileUseCase: EditProfileUseCase
  ) {
    this.createFormBuild();
  }

  async ngOnInit() {
    try {
      this.spinner.show();
      this.viewProfileUseCase.getUserProfile().subscribe((profile) => {
        const user = profile;
        if (user) {
          this.userProfileForm.setValue(this.setUserValue(user));
          this.disabledForm();
          this.spinner.hide();
        } else {
          // this.toast.info(
          //   "No user record was found, please enter your details then click update"
          // );
          const user = JSON.parse(localStorage.getItem("user"));
          if (user) {
            this.userProfileForm.controls['email'].setValue(user.userEmail);
            this.userProfileForm.controls['userId'].setValue(user.userId);
            this.userProfileForm.controls.email.disable();
          }
          this.spinner.hide();
        }
      });
    } catch (error) {
      this.toast.error("Error retrieving user information");
      this.spinner.hide();
    }
  }

  disabledForm() {
    this.userProfileForm.disable();
  }

  editProfile() {
    this.userProfileForm.enable();
    if (!this.userProfileForm.valid) {
      touchAllFormFields(this.userProfileForm);
      return;
    }
    this.editProfileUseCase.saveUserProfile(this.userProfileForm.value);
    this.router.navigateByUrl("/edit-profile");
  }

  private setUserValue(user: any) {
    const loggedOnUser = JSON.parse(localStorage.getItem("user"));
    return {
      ...user,
      email: loggedOnUser.userEmail,
    };
  }

  createFormBuild() {
    this.userProfileForm = this.formBuilder.group({
      userId: [""],
      name: [""],
      surname: [""],
      email: ["", Validators.required],
    });
  }
}
