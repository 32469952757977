import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

import { UserGateway } from "./user.gateways";
import { environment } from "src/environments/environment";
import { PaymentViewModel } from "../viewModels/payment.viewmodel";

const MERCHANT_ID = environment.merchant_id;
const MERCHANT_KEY = environment.merchant_key;
const PRODUCTION = environment.production;

@Injectable({
  providedIn: "root",
})
export class CheckoutGateway {
  domain: string;
  ITEM_NAME = "URF";
  ITEM_DESCRIPTION = "URF MUSIC";
  ADMIN_EMAIL = "tshepo.mashishi@urbanbrew.co.za";

  songs = [];
  user: any = {};
  userProfile: any = { name: '', surname: '' };
  payment: any = {};

  constructor(private userGateway: UserGateway,
    private firestore: AngularFirestore,
    private router: Router) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.domain = PRODUCTION ? "www.payfast" : "www.payfast"; //"sandbox.payfast";
    this.loadUserProfile();
  }

  checkout(payment: PaymentViewModel, songs: any[]) {
    payment.paymentId = this.uuidv4();
    this.payment = payment;
    this.songs = songs;
    const url =
      `https://${this.domain}.co.za/eng/process?merchant_id=` +
      MERCHANT_ID +
      "&merchant_key=" +
      MERCHANT_KEY +
      "&amount=" +
      payment.amount +
      "&payment_method=" +
      "" +
      "&item_name=" +
      this.ITEM_NAME +
      "&item_description=" +
      this.ITEM_DESCRIPTION +
      "&name_first=" +
      this.userProfile?.name +
      "&name_last=" +
      this.userProfile?.surname +
      "&email_address=" +
      JSON.parse(localStorage.getItem("user")).userEmail +
      "&email_confirmation" +
      true +
      "&confirmation_address=" +
      this.ADMIN_EMAIL +
      "&cancel_url=" +
      "https://urbanrhythmfactory.co.za/failed" +
      "&return_url=" +
      "https://urbanrhythmfactory.co.za/success" +
      "&notify_url=" +
      "https://urbanrhythmfactory.co.za/transaction-details";
    this.savePaymentInProgress();
    window.open(url, "_self");
  }

  savePaymentInProgress(): Promise<any> {
    const userPayment = this.createUserPaymentDetail();
    this.payment = userPayment;
    this.payment.paymentStatus = "PENDING";
    //this.payment.songs = JSON.stringify(this.songs);
    this.payment.paymentDate = new Date(),
      this.payment.paymentFailSuccessDate = new Date(),
      this.payment.songs = this.songs;

    localStorage.setItem("paymentId", this.payment.paymentId);
    const userPaymentsCollection = this.firestore.collection('UserPayment');

    return new Observable((observer) => {
      userPaymentsCollection.add(this.payment)
        .then((documentRef) => {
          const savedPayment = {
            ...this.payment,
            id: documentRef.id,
          };
          observer.next({
            message: 'Payment saved and Pending',
            data: savedPayment,
          });
          observer.complete();
        })
        .catch((error) => {
          observer.error({
            message: 'An error occurred while saving payment',
            error: error.message,
          });
        });
    }).toPromise();
  }

  updateSuccessfulUserPayment(): Promise<any> {
    const paymentId = localStorage.getItem("paymentId");
    const userPaymentsCollection = this.firestore.collection('UserPayment');

    return new Observable((observer) => {
      userPaymentsCollection
        .ref.where('paymentId', '==', paymentId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            observer.error({
              message: 'Payment not found',
            });
            return;
          }

          const documentSnapshot = querySnapshot.docs[0];
          const userPaymentDocRef = userPaymentsCollection.doc(documentSnapshot.id);

          const updateData = {
            paymentStatus: 'SUCCESS',
            paymentFailSuccessDate: new Date(),
          };

          userPaymentDocRef
            .update(updateData)
            .then(() => {
              observer.next({
                message: 'Payment was updated',
                data: { id: documentSnapshot.id, ...updateData },
              });
              observer.complete();
            })
            .catch((error) => {
              observer.error({
                message: 'An error occurred while updating payment',
                error: error.message,
              });
            });
        })
        .catch((error) => {
          observer.error({
            message: 'An error occurred while fetching payment',
            error: error.message,
          });
        });
    }).toPromise();
  }

  updateFailedUserPayment(): Promise<any> {
    const paymentId = localStorage.getItem("paymentId");
    const userPaymentsCollection = this.firestore.collection('UserPayment');

    return new Observable((observer) => {
      userPaymentsCollection
        .ref.where('paymentId', '==', paymentId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            observer.error({
              message: 'Payment not found',
            });
            return;
          }

          const documentSnapshot = querySnapshot.docs[0];
          const userPaymentDocRef = userPaymentsCollection.doc(documentSnapshot.id);

          const updateData = {
            paymentStatus: 'FAILED',
            paymentFailSuccessDate: new Date(),
          };

          userPaymentDocRef
            .update(updateData)
            .then(() => {
              observer.next({
                message: 'Payment was updated',
                data: { id: documentSnapshot.id, ...updateData },
              });
              observer.complete();
            })
            .catch((error) => {
              observer.error({
                message: 'An error occurred while updating payment',
                error: error.message,
              });
            });
        })
        .catch((error) => {
          observer.error({
            message: 'An error occurred while fetching payment',
            error: error.message,
          });
        });
    }).toPromise();
  }

  checkoutStatus(): Observable<any> {
    return;
    // const payment = new PaymentViewModel();
    // return this.http.post(BACKEND_URL + '/payfast', payment);
  }

  private uuidv4() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  private createUserPaymentDetail() {
    return {
      userId: this.user.userId,
      name: this.userProfile.name,
      surname: this.userProfile.surname,
      email: JSON.parse(localStorage.getItem("user")).userEmail,
      amount: this.payment.amount,
      paymentMethod: this.payment.paymentMethod,
      itemPurchased: this.ITEM_NAME,
      itemDescription: this.ITEM_DESCRIPTION,
      paymentId: this.payment.paymentId,
      paymentStatus: "PENDING",
      paymentDate: new Date(),
      paymentFailSuccessDate: new Date(),
    };
  }

  private loadUserProfile() {
    this.userGateway.getUserProfile().subscribe((resp: any) => {
      if (resp?.name) {
        this.userProfile.name = resp.name;
        this.userProfile.surname = resp.surname;
      } else {
        localStorage.clear();
        this.router.navigateByUrl("");
      }
    });
  }
}
