import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from "@angular/platform-browser";
import { BehaviorSubject, of } from "rxjs";
import { toInteger } from "lodash";
import { DatePipe } from "@angular/common";

import { PriceUseCase } from "src/app/useCases/price.usecase";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { TrackTableConfig } from "../../components/track-table/track-table.component";
import { UserContentService } from "src/app/services/user/content/user-content.service";

interface ORDER {
	orderId: number;
	items: {
		licenceType: string;
		durationMonths: number;
		startDate: Date;
		tracksTotal?: number;
		tracksDownloaded?: number;
		trackArray: RecordViewModel[];
	};
	licenceRate: number;
}

@Component({
	selector: "app-user-orders",
	templateUrl: "./user-orders.component.html",
	styleUrls: ["./user-orders.component.scss"],
})
export class UserOrdersComponent implements OnInit {
	public orderTrackConfig: TrackTableConfig = {
		tracksTableMetaData: false,
		paginationControls: false,
		licenceSelect: false,
		trackControls: "view",
	};

	public allMediaUsage$: BehaviorSubject<
		Map<string, { licence: PriceViewModel; songs: any[] }>
	> = new BehaviorSubject(null);
	public standard$: BehaviorSubject<any[]> = new BehaviorSubject(null);

	private prices$: BehaviorSubject<PriceViewModel[]> = new BehaviorSubject(
		[]
	);

	constructor(
		private userContentService: UserContentService,
		private priceUseCase: PriceUseCase,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.userContentService.getUserOrders().subscribe((orders) => {
			this.prices$.subscribe((prices) => {
				this.loadUserOrders(orders, prices);
			});
		});
		this.priceUseCase.getPrices().subscribe((prices) => {
			if (this.prices$.getValue().length == 0) this.prices$.next(prices);
		});
	}

	private groupBy = (orders, prices, keyGetter) => {
		const map = new Map<
			string,
			{ licence: PriceViewModel; songs: any[] }
		>();
		orders.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, {
					licence: prices.find((price) => price.key == key),
					songs: [item],
				});
			} else {
				collection.songs.push(item);
			}
		});
		return map;
	};
	private loadUserOrders(orders: any[], prices: PriceViewModel[]) {
		if (orders.length == 0 || prices.length == 0) return;
		const allMediaPriceId = prices.find(
			(price) => price.name == "All Media Usage"
		)?.key;
		this.allMediaUsage$.next(
			this.groupBy(
				orders
					.map((order) => {
						var formatedDate = this.formatPaymentDate(order.paymentDate);
						order.paymentDate = formatedDate;
						const songs = order.songs.filter(
							(song) => song.priceId === allMediaPriceId
						);
						return songs.length > 0
							? { ...order, songs: songs }
							: [];
					})
					.reduce(
						(prev, cur): any[] =>
							prev.concat(
								cur.songs
									? cur.songs.map((song) => {
										let ret = {
											...cur,
											...song,
										};
										delete ret.songs;
										return ret;
									})
									: []
							),
						[]
					),
				prices,
				(order) => order.priceId
			)
		);
		this.standard$.next(
			orders.map((order) => {
				return {
					...order,
					songs: this.groupBy(
						order.songs,
						prices,
						(song) => song.priceId
					),
				};
			})
		);
	}
	public orderEndDate(start: string, usage: string): Date {
		const startDate = new Date(start);
		const months = usage.toLowerCase() == "single" ? 1 : toInteger(usage);
		const endDate = new Date(
			startDate.setMonth(startDate.getMonth() + months)
		);
		return endDate;
	}
	public configForOrder(start: string, usage: string) {
		const endDate = this.orderEndDate(start, usage);
		const now = new Date(Date.now());

		if (endDate.getTime() > now.getTime())
			this.orderTrackConfig.trackControls = "download";
		else this.orderTrackConfig.trackControls = "extend";
		return this.orderTrackConfig;
	}
	public songObserver(songs: RecordViewModel[]) {
		return of(songs);
	}

	formatPaymentDate(timestamp: any): string {
		const seconds = timestamp.seconds;
		const nanoseconds = timestamp.nanoseconds;
		const milliseconds = seconds * 1000 + nanoseconds / 1000000;
		const date = new Date(milliseconds);
		return this.datePipe.transform(date, 'dd MMM yyyy') || '';
	}
}
