import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { DatePipe } from "@angular/common";

import { ModalModule } from "./utils/modal";
// @MM Ngx Imports
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxAudioPlayerModule } from "ngx-audio-player";
import { NgxPaginationModule } from "ngx-pagination";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { ViewProfileComponent } from "./pages/view-profile/view-profile.component";
import { UserPlaylistComponent } from "./pages/user-playlist/user-playlist.component";
import { UserCartComponent } from "./pages/user-cart/user-cart.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CuesheetsComponent } from "./pages/cuesheets/cuesheets.component";
import { UserOrdersComponent } from "./pages/user-orders/user-orders.component";
import { CheckoutSucceededComponent } from "./pages/checkout/checkout-succeeded/checkout-succeeded.component";
import { CheckoutFailedComponent } from "./pages/checkout/checkout-failed/checkout-failed.component";
import { UserCuesheetComponent } from "./pages/user-cuesheet/user-cuesheet.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { NotifyTransactionComponent } from "./pages/checkout/notify-transaction/notify-transaction.component";
import { environment } from "src/environments/environment";
import { ComponentsModule } from "./modules/components/components.module";
import { PagesModule } from "./modules/pages/pages.module";
import { ViewProfileUseCase } from "./useCases/view-profile.usecase";
import { EditProfileUseCase } from "./useCases/edit-profile.usecase";
import { LogoutUsecase } from "./useCases/logout.usecase";
import { AuthService } from "./services/security/auth-service";
import { GetPopularTracksUseCase } from "./useCases/get-popular-tracks.usecase";
import { PayFastUseCase } from "./useCases/payfast.usecase";
import { NotifyTransactionUseCase } from "./useCases/notify-transaction.usecase";
import { PriceUseCase } from "./useCases/price.usecase";
import { SearchUseCase } from "./useCases/search.usecase";
import { CheckoutPaymentComponent } from "./pages/checkout/checkout-payment/checkout-payment.component";
import { LogoutComponent } from "./sections/logout/logout.component";
import { MapComponent } from "./sections/map/map.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { UserContentService } from "./services/user/content/user-content.service";
import { CmsService } from "./services/cms/cms.service";
import { ServicePageComponent } from './pages/service-page/service-page.component';

@NgModule({
	declarations: [
		AppComponent,
		LogoutComponent,
		EditProfileComponent,
		ViewProfileComponent,
		UserPlaylistComponent,
		UserCartComponent,
		CheckoutComponent,
		CuesheetsComponent,
		UserOrdersComponent,
		CheckoutSucceededComponent,
		CheckoutFailedComponent,
		UserCuesheetComponent,
		PageNotFoundComponent,
		NotifyTransactionComponent,
		CheckoutPaymentComponent,
		MapComponent,
		ServicePageComponent,
	],
	imports: [
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		ModalModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatIconModule,
		HttpClientModule,
		AngularFireDatabaseModule,
		TooltipModule.forRoot(),
		BsDropdownModule.forRoot(),
		ToastrModule.forRoot(),
		NgxSpinnerModule,
		NgxAudioPlayerModule,
		NgxPaginationModule,
		ComponentsModule,
		PagesModule,
		GoogleMapsModule,
	],
	providers: [
		ViewProfileUseCase,
		EditProfileUseCase,
		LogoutUsecase,
		AuthService,
		GetPopularTracksUseCase,
		PayFastUseCase,
		NotifyTransactionUseCase,
		PriceUseCase,
		SearchUseCase,
		PriceUseCase,
		SearchUseCase,
		AuthService,
		UserContentService,
		CmsService,
		GetPopularTracksUseCase,
		DatePipe
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
