import { Component, OnInit } from "@angular/core";
import { ContactUsViewModel } from "src/app/viewModels/contact-us.viewmodel";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { touchAllFormFields } from "src/app/utils/validation";
import { CmsService } from "src/app/services/cms/cms.service";

@Component({
	selector: "app-contact-us",
	templateUrl: "./contact-us.component.html",
	styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
	contactUs: ContactUsViewModel;
	showContactUs = false;

	contactForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private toast: ToastrService,
		private getContactUsUsecase: CmsService
	) {
		this.createFormBuild();
	}

	ngOnInit() {
		this.loadContactUs();
	}

	send() {
		if (!this.contactForm.valid) {
			touchAllFormFields(this.contactForm);
			return;
		}
		this.toast.success("submitted successfully!!");
	}

	navigateTo(path: string) {
		const homeUrl = "/home";
		if (path) {
			const url = `${homeUrl}/${path}`;
			return this.router.navigateByUrl(url);
		}
		this.router.navigateByUrl("/home");
	}

	private loadContactUs() {
		this.getContactUsUsecase.getContactUs().subscribe((resp) => {
			if (resp) {
				this.showContactUs = true;
				this.contactUs = resp;
			}
		});
	}

	private createFormBuild() {
		this.contactForm = this.formBuilder.group({
			name: ["", Validators.required],
			contactNumber: ["", Validators.required],
			email: ["", [Validators.required, Validators.email]],
			enquiry: ["", Validators.required],
			message: ["", Validators.required],
		});
	}
}
