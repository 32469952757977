import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";

import { AuthService } from "./services/security/auth-service";
import { MatSidenav } from "@angular/material/sidenav";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { NavigationService } from "./services/navigation/navigation.service";
import { CmsService } from "./services/cms/cms.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("mobileSidenav") mobileSidenav: MatSidenav;
  @ViewChild("desktopSidenav") desktopSidenav: MatSidenav;

  title = "urf-client";
  studios$: Observable<any[]>;

  constructor(
    public router: Router,
    private authService: AuthService,
    private firebaseAuth: AngularFireAuth,
    private _bottomSheet: MatBottomSheet,
    private navigationService: NavigationService,
  ) {
  }

  public loggedInStatus = this.firebaseAuth.authState;

  ngOnInit(): void {
    if (!this.authService.loggedIn()) this.cleanAndNavigate();
  }

  ngAfterViewInit(): void {
    this.navigationService.desktopSidenav = this.desktopSidenav;
    this.navigationService.mobileSidenav = this.mobileSidenav;
    this.navigationService.mobileBottomSheet = this._bottomSheet;
  }

  isLoggedIn() {
    return !!localStorage.getItem("user");
  }

  private cleanAndNavigate() {
    localStorage.clear();
    this.router.navigateByUrl("");
  }
}
