import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/utils/modal";
import * as _ from "lodash";

import { CmsService } from "src/app/services/cms/cms.service";
import { StudioViewModel } from "src/app/viewModels/studio.viewmodel";
import { AboutUsViewModel } from "src/app/viewModels/about-us.viewmodel";

@Component({
	selector: "app-studio",
	templateUrl: "./studio.component.html",
	styleUrls: ["./studio.component.scss"],
})
export class StudioComponent implements OnInit {
	studios = [];
	showAboutUs = false;
	aboutUs: AboutUsViewModel;
	aboutUsCtx$: BehaviorSubject<{
		aboutUs: AboutUsViewModel;
	}> = new BehaviorSubject(null);
	private studiosSubject = new BehaviorSubject<any[]>([]);
	pictures = new BehaviorSubject<any[]>([]);
	pictures$: Observable<any[]> = this.pictures.asObservable();
	studios$: Observable<any[]> = this.studiosSubject.asObservable();
	studioDesc = "";

	showDialog = false;
	showDesc = false;

	constructor(
		private modalService: ModalService,
		private cmsService: CmsService,
		private cdr: ChangeDetectorRef
	) {
		this.cmsService.getStudioCount().pipe(
			map((resp) => {
				if (resp !== null) {
					const orderedStudios = this.reOrderCounter(resp);
					var studio = _.find(orderedStudios, { name: 'Studio 1' });
					if (studio) {
						this.fetchAndReplaceStudioData(studio);
					}
					return orderedStudios;
				}
				return [];
			})
		).subscribe((studios) => {
			this.studiosSubject.next(studios);
		});
	}

	fetchAndReplaceStudioData(studio: any) {
		this.pictures.next([]);
		this.cmsService.getStudioDetails(studio).then((response) => {
			this.pictures.next(response.pictures);
		}).catch((error) => {
			console.error('Error fetching studio details:', error);
		})
	}

	ngOnInit(): void {
		this.loadAboutUs();
	}

	book() {
		const id = "enquiry";
		this.modalService.add(id);
		this.openModal();
	}

	showStudioDesc(studio: StudioViewModel) {
		this.showDesc = true;
		this.studioDesc = studio.description;
	}

	public openModal() {
		this.showDialog = true;
		const id = "enquiry";
		this.modalService.add(id);
		this.modalService.open(id);
	}

	private reOrderCounter(studios: any): any {
		studios.sort((a: any, b: any) => a.counter - b.counter);
		studios.forEach((item: any, index: any) => {
			item.counter = index + 1;
		});
		return studios;
	}

	private loadAboutUs() {
		this.cmsService.getAboutUs().subscribe((resp) => {
			if (resp) {
				this.showAboutUs = true;
				this.aboutUs = resp;
				this.aboutUsCtx$.next({
					aboutUs: this.aboutUs,
				});
			}
		});
	}
}
