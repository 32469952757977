import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class CMSGateway {
    aboutUsCollection: AngularFirestoreCollection<any>;
    TcCollection: AngularFirestoreCollection<any>;
    ContactUsCollection: AngularFirestoreCollection<any>;
    FaqCollection: AngularFirestoreCollection<any>;
    FooterCollection: AngularFirestoreCollection<any>;
    StudioCollection: AngularFirestoreCollection<any>;

    constructor(private http: HttpClient,
        private firestore: AngularFirestore,
        private storage: AngularFireStorage,
        private toastr: ToastrService) {
        this.aboutUsCollection = this.firestore.collection<any>("AboutUs");
        this.TcCollection = this.firestore.collection<any>("Tc");
        this.ContactUsCollection = this.firestore.collection<any>("ContactUs");
        this.FaqCollection = this.firestore.collection<any>("Faq");
        this.FooterCollection = this.firestore.collection<any>("Footer");
        this.StudioCollection = this.firestore.collection<any>("Studio");
    }

    getAboutUs(): Observable<any> {
        return this.aboutUsCollection.snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((a: DocumentChangeAction<any>) => {
                    const data = a.payload.doc.data() as any;
                    data.key = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getTCs(): Observable<any> {
        return this.TcCollection.snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((a: DocumentChangeAction<any>) => {
                    const data = a.payload.doc.data() as any;
                    data.key = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getContactUs(): Observable<any> {
        return this.ContactUsCollection.snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((a: DocumentChangeAction<any>) => {
                    const data = a.payload.doc.data() as any;
                    data.key = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getFooter(): Observable<any> {
        return this.FooterCollection.snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((a: DocumentChangeAction<any>) => {
                    const data = a.payload.doc.data() as any;
                    data.key = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getFaq(): Observable<any> {
        return this.FaqCollection.snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((a: DocumentChangeAction<any>) => {
                    const data = a.payload.doc.data() as any;
                    data.key = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getStudios(): Observable<any> {
        return this.firestore.collection('Studios').snapshotChanges().pipe(
            switchMap((studios) =>
                combineLatest(
                    studios.map(async (studio: any) => {
                        const data = studio.payload.doc.data();
                        const id = studio.payload.doc.id;
                        const images = data.images || [];

                        const imageUrls$ = combineLatest(
                            images.map((image: any) => {
                                const path = `studio/${id}/${image}`;
                                const ref = this.storage.ref(path);
                                return ref.getDownloadURL();
                            })
                        );

                        const downloadUrls: any = await imageUrls$.toPromise();

                        return {
                            id,
                            name: data.name,
                            counter: data.counter,
                            description: data.description,
                            pictures: await Promise.all(
                                images.map(async (name: any, index: number) => ({
                                    file: await this.urlToFile(downloadUrls[index], name),
                                    imagePreview: downloadUrls[index],
                                }))
                            ),
                        };
                    })
                )
            )
        );
    }

    getStudioCount(): Observable<any[]> {
        return this.firestore.collection('Studios').snapshotChanges().pipe(
            map((actions: any) =>
                actions.map((a: any) => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {
                        id,
                        name: data.name,
                        counter: data.counter,
                        description: data.description,
                        images: data.images,
                    };
                })
            ),
            catchError(() => {
                this.toastr.error("Error loading studios", "Error");
                return of([]);
            })
        );
    }

    async getStudioDetails(studio: any): Promise<any> {
        const id = studio.id;
        const images = studio.images || [];

        const imageUrls$ = combineLatest(
            images.map((image: any) => {
                const path = `studio/${id}/${image}`;
                const ref = this.storage.ref(path);
                return ref.getDownloadURL();
            })
        );

        let downloadUrls: any[];
        try {
            downloadUrls = await imageUrls$.toPromise();
        } catch (error) {
            console.error("Error fetching download URLs", error);
            throw error;
        }

        const pictures = await Promise.all(
            images.map(async (name: any, index: number) => ({
                file: await this.urlToFile(downloadUrls[index], name),
                imagePreview: downloadUrls[index],
            }))
        );

        return {
            id: studio.id,
            name: studio.name,
            counter: studio.counter,
            description: studio.description,
            images: images,
            pictures
        };
    }

    async urlToFile(url: string, name: string): Promise<File> {
        const response = await fetch(url);
        const blob = await response.blob();

        return new File([blob], name, { type: blob.type });
    }
}
