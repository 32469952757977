import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable, forkJoin, from, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from "@angular/fire/firestore";

import { SignUpViewModel } from "../viewModels/sign-up.viewmodel";
import { EditProfileViewModel } from "../viewModels/edit-profile.viewmodel";
import { EnquiryViewModel } from "../viewModels/enquiry.viewmodel";
import { AuthService } from "../services/security/auth-service";
import { catchError, map, switchMap, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserGateway {
  loggedUser: any = {};

  PopularTrackCollection: AngularFirestoreCollection<any>;
  UserPlaylistTrackCollection: AngularFirestoreCollection<any>;
  EnquiryCollection: AngularFirestoreCollection<any>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private firebaseAuth: AngularFireAuth
  ) {
    this.EnquiryCollection = this.firestore.collection<any>("Enquiry");
    this.PopularTrackCollection = this.firestore.collection<any>("PopularTrack");
    this.UserPlaylistTrackCollection = this.firestore.collection<any>("UserPlaylist");
  }

  getUserProfile(): Observable<any> {
    return this.firebaseAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.firestore.collection('User').doc(user.uid).valueChanges();
        } else {
          // Handle the case when the user is not logged in
          return of(null);
        }
      }));
  }

  async login(login: any): Promise<any> {
    try {
      // @MM END HOTFIX
      const res: any = await this.firebaseAuth.signInWithEmailAndPassword(
        login.email,
        login.password
      );
      const jwt = await (await this.firebaseAuth.currentUser).getIdToken();
      const user = this.createLoggedOnSessionToken(res);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", JSON.stringify(jwt));
      return res;
    } catch (error) {
      console.error({ caughtError: error });
      this.firebaseAuth.sendSignInLinkToEmail(login.email, {
        url: window.location.toString(),
        handleCodeInApp: true,
      });
      return error;
    }
  }

  logout() {
    this.firebaseAuth.signOut();
    localStorage.clear();
    this.router.navigateByUrl("");
  }

  async signUp(signUpViewModel: SignUpViewModel): Promise<any> {
    const result = await this.firebaseAuth.createUserWithEmailAndPassword(
      signUpViewModel.email,
      signUpViewModel.password
    );
    if (result.user) {
      const userId = result.user.uid;
      this.saveUserInfo(signUpViewModel, userId);
      await this.sendEmailVerification();
      return result;
    } else {
      return result;
    }
  }

  async updateProfile(profile: EditProfileViewModel, callback): Promise<any> {
    (await this.firebaseAuth.currentUser)
      .updateEmail(profile.email)
      .then(async () => {
        if (profile.password) {
          (await this.firebaseAuth.currentUser)
            .updatePassword(profile.password)
            .then(() => {
              this.updateUserCredential(profile, callback);
            })
            .catch((error) => {
              return callback(error);
            });
        } else {
          this.updateUserCredential(profile, callback);
        }
      })
      .catch((error) => {
        return callback(error);
      });
  }

  async saveUserInfo(signUpVM: SignUpViewModel, id: string): Promise<any> {
    const user = this.createSignUpVModel(signUpVM, id);
    return this.firestore.collection('User').doc(id).set(user)
      .then(() => {
        console.log(`User information saved successfully`);
      })
      .catch((error: any) => {
        console.error(`Error saving user information: ${error.message}`);
        throw error;
      });
  }

  createUser(signUpViewModel: SignUpViewModel): any {
    return {
      name: signUpViewModel.name,
      surname: signUpViewModel.surname,
    };
  }

  async sendEmailVerification() {
    (await this.firebaseAuth.currentUser).sendEmailVerification();
  }

  addRecordToPlaylist(data: any): Observable<any> {
    this.getUserDetail();
    data.userId = this.loggedUser.userId;
    const { userId, songId } = data;
    // Reference to the collection, not a specific document
    const popularTracksRef = this.firestore.collection('PopularTrack');
    const userPlaylistsRef = this.firestore.collection('UserPlaylist');

    return popularTracksRef
      .doc(`${userId}_${songId}`) // Create a unique path using userId and songId
      .get()
      .pipe(
        switchMap((popularTrackDoc) => {
          if (popularTrackDoc.exists) {
            // Record already exists in popularTracks
            return of({ message: 'Song already exists in popular tracks', data: false });
          }

          // If record doesn't exist in popularTracks, check userPlaylists
          return userPlaylistsRef
            .doc(`${userId}_${songId}`) // Create a unique path using userId and songId
            .get();
        }),
        switchMap((userPlaylistDoc: any) => {
          if (userPlaylistDoc.exists) {
            // Record already exists in userPlaylists
            return of({ message: 'Song already exists in user playlist', data: false });
          }

          // Save to popularTracks
          const popularTrackRef = popularTracksRef.doc(`${userId}_${songId}`);
          const popularTrack$ = popularTrackRef.set(data);

          // Save to userPlaylists
          const userPlaylistRef = userPlaylistsRef.doc(`${userId}_${songId}`);
          const userPlaylist$: any = userPlaylistRef.set(data);

          return forkJoin([userPlaylist$, popularTrack$]).pipe(
            tap(() => console.log('Records saved successfully')),
            catchError((error) => {
              console.error('An error occurred:', error);
              return of({ message: 'An error occurred', data: error });
            })
          );
        })
      );
  }

  public getUserPlaylist(): Observable<any> {
    this.getUserDetail();
    return this.firestore.collection('UserPlaylist', (ref) =>
      ref.where('userId', '==', this.loggedUser.userId)
    ).valueChanges();
  }

  public getUserCarts(): Observable<any> {
    this.getUserDetail();
    return this.firestore.collection('UserCart', (ref) =>
      ref.where('userId', '==', this.loggedUser.userId)
    ).valueChanges();
  }

  public addToCart(data: any): Observable<any> {
    this.getUserDetail();
    data.userId = this.loggedUser.userId;
    const { userId, songId } = data;

    return from(
      this.firestore
        .collection('UserCart', (ref) =>
          ref.where('userId', '==', userId).where('songId', '==', songId)
        )
        .get()
        .pipe(
          switchMap((querySnapshot) => {
            if (!querySnapshot.empty) {
              // Record already exists in userCarts
              return of({ message: 'Song already exists in user cart', data: false });
            }

            // Save to userCarts
            return from(
              this.firestore.collection('UserCart').add(data).then((docRef) => {
                console.log('Document written with ID: ', docRef.id);
                return {
                  message: 'Successfully saved!',
                  data: data,
                };
              })
            );
          }),
          catchError((error) => {
            console.error('Error adding document: ', error);
            return of({
              message: 'An error occurred',
              data: undefined,
            });
          })
        )
    );
  }

  public removeFromCart(data: any): Observable<any> {
    this.getUserDetail();
    data.userId = this.loggedUser.userId;
    const { userId, songId } = data;

    const userCartDocRef = this.firestore.collection('UserCart', (ref) =>
      ref.where('userId', '==', userId).where('songId', '==', songId)
    );

    return from(
      userCartDocRef.get().pipe(
        catchError((error) => {
          console.error('Error fetching document:', error);
          throw error;
        })
      )
    ).pipe(
      catchError((error) => {
        console.error('Error removing from cart:', error);
        throw error;
      }),

      switchMap((querySnapshot) => {
        if (querySnapshot.size === 0) {
          return of(false);
        }

        const docId = querySnapshot.docs[0].id;
        const docRef = this.firestore.collection('UserCart').doc(docId);

        return from(docRef.delete()).pipe(
          switchMap(() => of(true)),
          catchError((deleteError: any) => {
            console.error('Error deleting document:', deleteError);
            throw deleteError;
          })
        );
      }),
      catchError((error) => {
        console.error('Error removing from cart', error);
        throw error;
      })
    );
  }

  public removeSongFromPlaylist(data: any): Observable<any> {
    this.getUserDetail();
    data.userId = this.loggedUser.userId;
    const { userId, songId } = data;

    const userCartDocRef = this.firestore.collection('UserPlaylist', (ref) =>
      ref.where('userId', '==', userId).where('songId', '==', songId)
    );

    return from(
      userCartDocRef.get().pipe(
        catchError((error) => {
          console.error('Error fetching document:', error);
          throw error;
        })
      )
    ).pipe(
      catchError((error) => {
        console.error('Error removing from cart:', error);
        throw error;
      }),

      switchMap((querySnapshot) => {
        if (querySnapshot.size === 0) {
          return of(false);
        }

        const docId = querySnapshot.docs[0].id;
        const docRef = this.firestore.collection('UserPlaylist').doc(docId);

        return from(docRef.delete()).pipe(
          switchMap(() => of(true)),
          catchError((deleteError: any) => {
            console.error('Error deleting document:', deleteError);
            throw deleteError;
          })
        );
      }),
      catchError((error) => {
        console.error('Error removing from cart', error);
        throw error;
      })
    );
  }

  saveCueSheet(cuesheet: any): Observable<any> {
    this.getUserDetail();
    cuesheet.userId = this.loggedUser.userId;

    if (!cuesheet.id) {
      return from(this.firestore.collection('Cuesheet').add(cuesheet)).pipe(
        catchError((error) => {
          console.error('Error saving cuesheet:', error);
          throw error;
        })
      );
    } else {
      const cuesheetId = cuesheet.id;
      delete cuesheet.id;

      return from(this.firestore.collection('Cuesheet').doc(cuesheetId).update(cuesheet)).pipe(
        catchError((error) => {
          console.error('Error updating cuesheet:', error);
          throw error;
        })
      );
    }
  }

  getUserCuesheet(): Observable<any> {
    this.getUserDetail();
    return this.firestore
      .collection('Cuesheet', (ref) => ref.where('userId', '==', this.loggedUser.userId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  userEnquiry(enquiry: EnquiryViewModel): Promise<DocumentReference> {
    return this.EnquiryCollection.add(enquiry);
  }

  getUserOrders(): Observable<any> {
    this.getUserDetail();
    return this.firestore
      .collection('UserPayment', (ref) =>
        ref.where('userId', '==', this.loggedUser.userId)
          .where('paymentStatus', '==', 'SUCCESS'))
      .get()
      .pipe(
        map((querySnapshot) => {
          const payments: any[] = [];
          querySnapshot.forEach((doc) => {
            payments.push(doc.data());
          });
          return payments;
        })
      );
  }

  removeAllFromCarts(): Observable<any> {
    this.getUserDetail();
    const userCartDocRef = this.firestore.collection('UserCart', (ref) =>
      ref.where('userId', '==', this.loggedUser.userId));

    return from(
      userCartDocRef.get().pipe(
        catchError((error) => {
          console.error('Error fetching document:', error);
          throw error;
        })
      )
    ).pipe(
      catchError((error) => {
        console.error('Error removing from cart:', error);
        throw error;
      }),

      switchMap((querySnapshot) => {
        if (querySnapshot.size === 0) {
          return of(false);
        }

        const docId = querySnapshot.docs[0].id;
        const docRef = this.firestore.collection('UserCart').doc(docId);

        return from(docRef.delete()).pipe(
          switchMap(() => of(true)),
          catchError((deleteError: any) => {
            console.error('Error deleting document:', deleteError);
            throw deleteError;
          })
        );
      }),
      catchError((error) => {
        console.error('Error removing from cart', error);
        throw error;
      })
    );
  }

  deleteCuesheet(id: string): Observable<any> {
    this.getUserDetail();
    const userCartDocRef = this.firestore.collection('Cuesheet').doc(id);

    return from(userCartDocRef.get()).pipe(
      switchMap((docSnapshot) => {
        if (!docSnapshot.exists) {
          return of(false);
        }

        return from(docSnapshot.ref.delete()).pipe(
          map(() => true),
          catchError((deleteError) => {
            console.error('Error deleting document:', deleteError);
            throw deleteError;
          })
        );
      }),
      catchError((error) => {
        console.error('Error fetching document:', error);
        throw error;
      })
    );
  }

  checkIfPaymentWasMade(): Observable<any> {
    const paymentId = localStorage.getItem("paymentId");
    return this.firestore.collection('UserPayment', (ref) =>
      ref.where('paymentId', '==', paymentId).where('paymentStatus', '==', 'SUCCESS')
    ).valueChanges().pipe(
      map((documents) => {
        if (documents.length > 0) {
          return {
            message: 'Payment was fetched successfully',
            data: documents[0],
          };
        } else {
          return {
            message: 'Payment not found',
            data: null,
          };
        }
      })
    );
  }

  userHasPayment(): Observable<any> {
    var user = JSON.parse(localStorage.getItem("user"));
    return this.firestore
      .collection('UserPayment', (ref) =>
        ref.where('userId', '==', user.userId).where('paymentStatus', '==', 'SUCCESS')
      )
      .get()
      .pipe(
        map((querySnapshot) => {
          return !querySnapshot.empty;
        })
      );
  }

  private getUserDetail(): any {
    this.loggedUser = JSON.parse(localStorage.getItem("user"));

    if (!this.authService.loggedIn()) return this.logout();

    return this.loggedUser;
  }

  private createLoggedOnSessionToken(res: any) {
    return {
      userId: res.user.uid,
      userEmail: res.user.email,
    };
  }

  private updateUserCredential(profile: EditProfileViewModel, callback: any): Observable<any> {
    const user = this.createUpdateUserModel(profile);
    const docRef = this.firestore.collection('User').doc(user.userId);

    return from(docRef.update(user)
      .then(() => {
        //return { message: 'User credentials updated successfully' };
        return callback(true);
      })
      .catch((error) => {
        // Handle the error
        console.error('Error updating user:', error);
        //throw error; // Rethrow the error to be caught by the Observable
        return callback(false);
      }));
  }

  private createUpdateUserModel(user: EditProfileViewModel) {
    return {
      userId: user.userId,
      name: user.name,
      surname: user.surname,
    };
  }

  private createSignUpVModel(SignUpvm: SignUpViewModel, id: string): any {
    return {
      userId: id,
      name: SignUpvm.name,
      surname: SignUpvm.surname,
    };
  }
}
