import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "src/app/pages/home/home.component";
import { PricingComponent } from "src/app/pages/pricing/pricing.component";
import { AboutUsComponent } from "src/app/pages/about-us/about-us.component";
import { StudioComponent } from "src/app/pages/studio/studio.component";
import { PlaylistTracksComponent } from "src/app/pages/playlist-tracks/playlist-tracks.component";
import { FaqComponent } from "src/app/pages/faq/faq.component";
import { ContactUsComponent } from "src/app/pages/contact-us/contact-us.component";
import { TcComponent } from "src/app/pages/tc/tc.component";
import { SignUpComponent } from "src/app/pages/sign-up/sign-up.component";
import { NgModule } from "@angular/core";
import { MusicLibraryComponent } from "src/app/pages/music-library/music-library.component";
import { BrowsePlaylistsComponent } from "src/app/pages/browse-playlists/browse-playlists.component";
import { ComposersComponent } from "src/app/pages/composers/composers.component";
import { SearchContainerComponent } from "src/app/pages/search/search-container/search-container.component";
import { LoginComponent } from "src/app/sections/login/login.component";
import { MusicSearchComponent } from "src/app/sections/music-search/music-search.component";
import { ServicePageComponent } from "src/app/pages/service-page/service-page.component";

const routes: Routes = [
  {
    path: "",
    // data: {
    // 	breadcrumb: "HOME",
    // },
    component: HomeComponent,
  },
  {
    path: "pricing",
    data: {
      breadcrumb: "PRICE",
    },
    component: PricingComponent,
  },
  {
    path: "about-us",
    data: {
      breadcrumb: "ABOUT",
    },
    children: [
      {
        path: "",
        data: {
          breadcrumb: null,
        },
        component: AboutUsComponent,
      },
      {
        path: "studio",
        data: {
          breadcrumb: "AUDIO POST-PRODUCTION STUDIOS",
        },
        component: StudioComponent,
      },
      {
        path: "contact-us",
        data: {
          breadcrumb: "CONTACT",
        },
        component: ContactUsComponent,
      },
      {
        path: "music-library",
        data: {
          breadcrumb: "MUSIC LIBRARY",
        },
        component: MusicLibraryComponent,
      },
    ],
  },
  {
    path: "music-library",
    data: {
      breadcrumb: "MUSIC LIBRARY",
    },
    children: [
      {
        path: "",
        component: MusicLibraryComponent,
      },
      {
        path: "playlist",
        data: {
          breadcrumb: "PLAYLIST",
        },
        children: [
          {
            path: "",
            data: {
              breadcrumb: null,
            },
            component: BrowsePlaylistsComponent,
          },
          {
            path: "playlist-tracks",
            data: {
              breadcrumb: "PLAYLIST TRACKS",
            },
            component: PlaylistTracksComponent,
          },
        ],
      },
    ],
  },
  {
    path: "services",
    data: {
      breadcrumb: "SERVICE",
    },
    component: ServicePageComponent,
  },
  {
    path: "faq",
    data: {
      breadcrumb: "FAQ",
    },
    component: FaqComponent,
  },
  {
    path: "contact-us",
    data: {
      breadcrumb: "CONTACT",
    },
    component: ContactUsComponent,
  },
  {
    path: "search",
    data: {
      breadcrumb: "SEARCH RESULTS",
    },
    children: [
      {
        path: "",
        component: MusicSearchComponent,
      },
      {
        path: "advanced",
        data: {
          breadcrumb: "ADVANCED",
        },
        component: SearchContainerComponent,
      },
    ],
  },
  {
    path: "tc",
    data: {
      breadcrumb: "TERMS AND CONDITIONS",
    },
    component: TcComponent,
  },
  {
    path: "playlist",
    data: {
      breadcrumb: "PLAYLIST",
    },
    component: BrowsePlaylistsComponent,
  },
  {
    path: "composer",
    data: {
      breadcrumb: "COMPOSER",
    },
    component: ComposersComponent,
  },
  {
    path: "login",
    data: {
      breadcrumb: "LOGIN",
    },
    component: LoginComponent,
  },
  {
    path: "sign-up",
    data: {
      breadcrumb: "SIGN UP",
    },
    component: SignUpComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
