import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";

import { GetPlaylistUseCase } from "src/app/useCases/get-playlist.usecase";

const DOMAIN ='https://urbanrhythmfactory.sourceaudio.com';

@Component({
  selector: "app-playlist",
  templateUrl: "./playlist.component.html",
  styleUrls: ["./playlist.component.scss"],
})
export class PlaylistComponent implements OnInit {
  playlists: any[];
  itemsToDisplay = 10;

  constructor(
    private getPlaylistUseCase: GetPlaylistUseCase,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadPlaylist();
  }

  showTracks(playlist) {
    this.router.navigate(["/home/music-library/playlist/playlist-tracks", playlist.playlistId]);
  }

  private loadPlaylist() {
    this.spinner.show();
    this.getPlaylistUseCase.get().subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.length) {
        const result = resp;
        this.playlists = result;
        for (let i = 0; i < this.playlists.length; i++) {
          this.playlists[i].playlistId = this.playlists[i].id;
          this.playlists[i].imageUrl = DOMAIN + this.playlists[i].image;
        }

        this.playlists = result.slice(0, this.itemsToDisplay);
      }
    });
  }

  public emptyArray(length: number): any[] {
    if (length < 3) return Array(3 - length);
    return Array(length % 3);
  }
}
