import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { touchAllFormFields } from "src/app/utils/validation";
import { EnquiryUsecase } from "src/app/useCases/enquiry.usecase";
import { EnquiryViewModel } from "src/app/viewModels/enquiry.viewmodel";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-pricing",
	templateUrl: "./pricing.component.html",
	styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
	messageForm: FormGroup;

	constructor(
		private priceUseCase: PriceUseCase,
		private formBuilder: FormBuilder,
		private toast: ToastrService,
		private spinner: NgxSpinnerService,
		private enquiryUseCase: EnquiryUsecase
	) {}

	ngOnInit() {
		this.createFormBuild();
	}

	send() {
		if (!this.messageForm.valid) {
			touchAllFormFields(this.messageForm);
			return;
		}
		// console.log(this.messageForm.value);
		return;
		this.enquiryUseCase.enquire(this.messageForm.value);
		this.toast.success("submitted successfully!!");
	}

	private createFormBuild() {
		this.messageForm = this.formBuilder.group({
			name: ["", Validators.required],
			contactNumber: ["", Validators.required],
			email: ["", [Validators.required, Validators.email]],
			enquiry: ["", Validators.required],
			message: ["", Validators.required],
		});
	}
}
