import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { CmsService } from 'src/app/services/cms/cms.service';
import { AboutUsViewModel } from 'src/app/viewModels/about-us.viewmodel';

@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent implements OnInit {
  aboutUs: AboutUsViewModel;
  showAboutUs = false;

  aboutUsCtx$: BehaviorSubject<{
    aboutUs: AboutUsViewModel;
  }> = new BehaviorSubject(null);

  constructor(private getAboutUs: CmsService, private router: Router) { }

  ngOnInit(): void {
    this.loadAboutUs();
  }

  private loadAboutUs() {
    this.getAboutUs.getAboutUs().subscribe((resp) => {
      if (resp) {
        this.showAboutUs = true;
        this.aboutUs = resp;
        this.aboutUsCtx$.next({
          aboutUs: this.aboutUs,
        });
      }
    });
  }

  navigateTo(url: string){
    this.router.navigateByUrl(`home/${url}`);
  }
}
