import { Injectable } from "@angular/core";

import { CheckoutGateway } from "../gateways/checkout.gateways";
import { PaymentViewModel } from "../viewModels/payment.viewmodel";

@Injectable({
	providedIn: "root",
})
export class PayFastUseCase {
	previousUrl: string;

	constructor(private checkoutGateway: CheckoutGateway) {}

	checkout(payment: PaymentViewModel, songs: any[]) {
		return this.checkoutGateway.checkout(payment, songs);
	}

	updateSuccessfulUserPayment() {
		return this.checkoutGateway.updateSuccessfulUserPayment();
	}

	updateFailedUserPayment() {
		return this.checkoutGateway.updateFailedUserPayment();
	}

	saveCurrentUrl(url: string) {
		this.previousUrl = url;
	}

	getPreviousUrl() {
		return this.previousUrl;
	}
}
