import { ArrayDataSource } from "@angular/cdk/collections";
import { NestedTreeControl } from "@angular/cdk/tree";
import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatSidenav } from "@angular/material/sidenav";
import { Observable } from "rxjs";
import { UserCartComponent } from "src/app/pages/user-cart/user-cart.component";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { ModalService } from "src/app/utils/modal";
interface MenuItem {
	title: string;
	click?: () => any;
	path?: string;
	icon?: string;
	class?: string[];
	children?: MenuItem[];
}
@Component({
	selector: "urf-mobile-header",
	templateUrl: "./mobile-header.component.html",
	styleUrls: ["./mobile-header.component.scss"],
})
export class MobileHeaderComponent implements OnInit {
	@Output("shouldClose") shouldClose = new EventEmitter<void>();

	public treeControl = new NestedTreeControl<MenuItem>(
		(node) => node.children
	);
	public cartCount$: Observable<number>;

	constructor(
		private _bottomSheet: MatBottomSheet,
		private userContentService: UserContentService,
		private modalService: ModalService
	) {
		this.cartCount$ = this.userContentService.cartCount$;
	}

	ngOnInit(): void {}

	public navItemClicked = (item: MenuItem) => {
		// @MM Define standard functionality to close sidenav on click.
		this.shouldClose.emit();
		if (item.click) item.click();
	};
	public isLoggedIn = () => {
		// @MM Return logged in status.
		// TODO: Change to active login check.
		return !!localStorage.getItem("user");
	};
	public login = () => {
		const id = "login";
		this.modalService.add(id);
		this.modalService.open(id);
		return true;
	};

	public signUp = () => {
		const id = "sign-up";
		this.modalService.add(id);
		this.modalService.open(id);
		return true;
	};
	public openBottomSheet = () => {
		this.shouldClose.emit();
		this._bottomSheet.open(UserCartComponent);
	};
	public hasChild = (_: number, node: MenuItem) =>
		!!node.children && node.children.length > 0;

	public StandardMenu: MenuItem[] = [
		{ title: "about", path: "home/about-us" },
		{ title: "service", path: "home/services" },
		{ title: "music-library", path: "home/music-library" },
		{ title: "faq", path: "home/faq" },
		{ title: "contact", path: "home/contact-us" },
		{
			title: "music search",
			path: "home/search",
			icon: "search",
			class: ["music-search"],
		},
	];
	public UserItems: MenuItem[] = [
		{
			title: "my account",
			children: [
				{
					title: "my profile",
					path: "/view-profile",
					class: ["child-item"],
				},
				{
					title: "my favorite tracks",
					path: "/user-playlist",
					class: ["child-item"],
				},
				{
					title: "my orders",
					path: "/user-orders",
					class: ["child-item"],
				},
				{
					title: "my cuesheet",
					path: "/user-cuesheet",
					class: ["child-item"],
				},
			],
		},
	];
	public GuestControls: MenuItem[] = [
		{ title: "login", click: this.login, class: ["outline-control"] },
		{ title: "sign up", click: this.signUp, class: ["solid-control"] },
	];
	public UserControls: MenuItem[] = [
		{ title: "logout", path: "/logout", class: ["outline-control"] },
	];
	public userItemsCtx = {
		nodeArray: new ArrayDataSource(this.UserItems),
		nodeControl: this.treeControl,
	};
	public standardItemsCtx = {
		nodeArray: new ArrayDataSource(this.StandardMenu),
		nodeControl: this.treeControl,
	};
}
