import { Component, OnInit } from "@angular/core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

@Component({
	selector: "app-music-search",
	templateUrl: "./music-search.component.html",
	styleUrls: ["./music-search.component.scss"],
})
export class MusicSearchComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
