import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable, of } from "rxjs";

import { CMSGateway } from "src/app/gateways/cms.geteways";

@Injectable({
	providedIn: "root",
})
export class CmsService {
	private aboutUs$: BehaviorSubject<any> = new BehaviorSubject(null);
	private contactUs$: BehaviorSubject<any> = new BehaviorSubject(null);
	private faq$: BehaviorSubject<any> = new BehaviorSubject(null);
	private tc$: BehaviorSubject<any> = new BehaviorSubject(null);
	private studios$: BehaviorSubject<any> = new BehaviorSubject(null);
	private footer$: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(private cmsGateway: CMSGateway, private spinner: NgxSpinnerService) { }

	public getAboutUs(): Observable<any> {
		if (this.aboutUs$.getValue() == null)
			this.cmsGateway.getAboutUs().subscribe((res) => {
				if (res[0]) this.aboutUs$.next(res[0]);
			});
		return this.aboutUs$;
	}
	public getContactUs(): Observable<any> {
		if (this.contactUs$.getValue() == null)
			this.cmsGateway.getContactUs().subscribe((res) => {
				if (res[0]) this.contactUs$.next(res[0]);
			});
		return this.contactUs$;
	}
	public getFaq(): Observable<any> {
		if (this.faq$.getValue() == null)
			this.cmsGateway.getFaq().subscribe((faq) => {
				if (faq[0]) this.faq$.next(faq[0]);
			});
		return this.faq$;
	}
	public getTC(): Observable<any> {
		if (this.tc$.getValue() == null)
			this.cmsGateway.getTCs().subscribe((tc) => {
				if (tc[0]) this.tc$.next(tc[0]);
			});
		return this.tc$;
	}

	public getStudios(): Observable<any> {
		if (this.studios$.getValue() == null)
			this.spinner.show();
		this.cmsGateway.getStudios().subscribe((res) => {
			if (res) {
				this.studios$.next(res);
			}
		});
		this.spinner.hide();
		return this.studios$;
	}

	public getFooter(): Observable<any> {
		if (this.footer$.getValue() == null)
			this.cmsGateway.getFooter().subscribe((res) => {
				if (res[0]) this.footer$.next(res[0]);
			});
		return this.footer$;
	}

	getStudioCount(): Observable<any> {
		if (this.studios$.getValue() == null)
			this.spinner.show();

		this.cmsGateway.getStudioCount().subscribe((response: any) => {
			this.spinner.hide();
			if (!response.length) {
				return this.studios$.next([]);
			}
			
			let sorted_array = _.orderBy(response, ['name'], ['asc', 'desc']);
			return this.studios$.next(sorted_array);
		});

		this.spinner.hide();
		return this.studios$;
	}

	getStudioDetails(studio: any) {
		return this.cmsGateway.getStudioDetails(studio);
	}
}
