import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";

import { ComposerViewModel } from "../viewModels/composer.viewmodel";
import { GenreViewModel } from "../viewModels/genre.viewmodel";
import { InstrumentViewModel } from "../viewModels/instrument.viewmodel";
import { MoodViewModel } from "../viewModels/mood.viewmodel";
import { TempoViewModel } from "../viewModels/tempo.viewmodel";
import { RecordViewModel } from "../viewModels/record.viewmodel";
import { environment } from "src/environments/environment";

const AUTH_TOKEN = environment.source_audio_token;
const DOMAIN = environment.source_audio_domain;

@Injectable({
  providedIn: "root",
})
export class SearchGateway {
  trackCollection: AngularFirestoreCollection<RecordViewModel>;
  composerCollection: AngularFirestoreCollection<ComposerViewModel>;
  genreCollection: AngularFirestoreCollection<GenreViewModel>;
  instrumentCollection: AngularFirestoreCollection<InstrumentViewModel>;
  moodCollection: AngularFirestoreCollection<MoodViewModel>;
  tempoCollection: AngularFirestoreCollection<TempoViewModel>;

  composers: Observable<ComposerViewModel[]>;
  genres: Observable<GenreViewModel[]>;
  instruments: Observable<InstrumentViewModel[]>;
  moods: Observable<MoodViewModel[]>;
  tempos: Observable<TempoViewModel[]>;

  constructor(private fdb: AngularFirestore, private http: HttpClient) {
    this.composerCollection = this.fdb.collection("Composers");
    this.genreCollection = this.fdb.collection("Genres");
    this.instrumentCollection = this.fdb.collection("Instruments");
    this.moodCollection = this.fdb.collection("Moods");
    this.tempoCollection = this.fdb.collection("Tempos");
  }

  search(searchStr: string): Observable<any> {
    const url = `${DOMAIN}/tracks/search?s=${searchStr}&token=${AUTH_TOKEN}`;
    return this.http.get(url);
  }

  getComposers(): Observable<any[]> {
    return (this.composers = this.composerCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as ComposerViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getGenres(): Observable<any[]> {
    return (this.genres = this.genreCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as GenreViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getInstruments(): Observable<any[]> {
    return (this.instruments = this.instrumentCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstrumentViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getMoods(): Observable<any[]> {
    return (this.moods = this.moodCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as MoodViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getTempos(): Observable<any> {
    return (this.tempos = this.tempoCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as TempoViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }
}
