import { Injectable } from '@angular/core';
import { UserGateway } from '../gateways/user.gateways';
import { SignUpViewModel } from '../viewModels/sign-up.viewmodel';

@Injectable({
  providedIn: 'root'
})
export class SignUpUsecase {
  constructor(private userGateway: UserGateway) { }

  signUp(signUpViewModel: SignUpViewModel): Promise<any> {
    return this.userGateway.signUp(signUpViewModel);
  }
}
