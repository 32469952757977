import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthService {
  loggedIn() {
    const helper = new JwtHelperService();
    const raw = localStorage.getItem("token");
    const token = raw == "undefined" ? null : raw; // @MM Prevent parsing 'undefined'.
    if (token) {
      const isExpired = helper.isTokenExpired(token);
      if (!isExpired) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
