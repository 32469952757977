import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { CmsService } from "src/app/services/cms/cms.service";
import { AboutUsViewModel } from "src/app/viewModels/about-us.viewmodel";

@Component({
	selector: "app-about-us",
	templateUrl: "./about-us.component.html",
	styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
	aboutUs: AboutUsViewModel;
	showAboutUs = false;

	aboutUsCtx$: BehaviorSubject<{
		aboutUs: AboutUsViewModel;
	}> = new BehaviorSubject(null);

	constructor(private router: Router, private getAboutUs: CmsService) { }

	ngOnInit() {
		this.loadAboutUs();
	}

	visit() {
		this.router.navigateByUrl("home/about-us/studio");
	}

	private loadAboutUs() {
		this.getAboutUs.getAboutUs().subscribe((resp) => {
			if (resp) {
				this.showAboutUs = true;
				this.aboutUs = resp;
				this.aboutUsCtx$.next({
					aboutUs: this.aboutUs,
				});
			}
		});
	}
}
