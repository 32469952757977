import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CmsService } from "src/app/services/cms/cms.service";

interface FAQ {
  title: string;
  content: string;
}
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  // faq: FaqViewModel;
  public faq$: BehaviorSubject<FAQ[]> = new BehaviorSubject([]);
  public showFaq = false;
  private domParser: DOMParser = new DOMParser();

  constructor(private getFaqUsecase: CmsService) {}

  ngOnInit() {
    this.loadFaq();
  }

  private loadFaq() {
    this.getFaqUsecase.getFaq().subscribe((resp) => {
      if (resp) {
        let sections: FAQ[] = [];
        let parsed = this.domParser.parseFromString(
          unescape(resp.faq),
          "text/html"
        );
        // @MM Loop through parsed html.
        for (let child = 0; child < parsed.body.children.length; child++) {
          const element = parsed.body.children[child];
          if (element.tagName == "H3") {
            // @MM New section.
            var plainText = element.innerHTML.replace(/<[^>]*>/g, "");
            sections.push({
              title: plainText,
              content: "",
            });
          } else {
            // @MM Not a new section. Add content.
            if (sections.length > 0)
              sections[sections.length - 1].content += element.outerHTML;
          }
        }
        this.faq$.next(sections);
      }
    });
  }
}
