import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchGateway } from '../gateways/search.gateways';

@Injectable()
export class SearchUseCase {
    constructor(private searchGateway: SearchGateway) { }

    search(searchStr: string): Observable<any> {
        return this.searchGateway.search(searchStr);
    }

    getTempos(): Observable<any[]> {
        return this.searchGateway.getTempos();
    }
    getMoods(): Observable<any[]> {
        return this.searchGateway.getMoods();
    }
    getInstruments(): Observable<any[]> {
        return this.searchGateway.getInstruments();
    }
    getGenres(): Observable<any[]> {
        return this.searchGateway.getGenres();
    }
    getComposers(): Observable<any[]> {
        return this.searchGateway.getComposers();
    }
}
