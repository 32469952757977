import { Injectable } from '@angular/core';
import { UserGateway } from '../gateways/user.gateways';
import { LoginViewModel } from '../viewModels/login.viewmodel';

@Injectable({
  providedIn: 'root'
})
export class LoginUsecase {
  showDialog = true;

  constructor(private userGateway: UserGateway) { }

  async login(login: LoginViewModel): Promise<any> {
    return this.userGateway.login(login);
  }

  setModalDialog(value: boolean) {
    this.showDialog = value;
  }

  getModelDialog() {
    return this.showDialog;
  }
}
