import { Component, OnInit } from "@angular/core";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterViewModel } from "src/app/viewModels/home-footer.viewmodel";
import { ContactUsViewModel } from "src/app/viewModels/contact-us.viewmodel";
import { Router } from "@angular/router";
import { CmsService } from "src/app/services/cms/cms.service";

@Component({
	selector: "app-home-footer",
	templateUrl: "./home-footer.component.html",
	styleUrls: ["./home-footer.component.scss"],
})
export class HomeFooterComponent implements OnInit {
	faFacebook = faFacebook;
	faTwitter = faTwitter;
	faInstagram = faInstagram;

	footer = new FooterViewModel();
	showFooter = false;

	contactUs = new ContactUsViewModel();
	showcontacUs = false;

	constructor(private router: Router, private cmsService: CmsService) {}

	ngOnInit() {
		this.loadFooterSocial();
		this.loadFooterContactUs();
	}

	navigateTo(path: string) {
		const homeUrl = "/home";
		if (path) {
			const url = `${homeUrl}/${path}`;
			return this.router.navigateByUrl(url);
		}
		this.router.navigateByUrl("/home");
	}

	private loadFooterSocial() {
		this.cmsService.getFooter().subscribe((resp) => {
			if (resp.data) {
				this.removeHTMLTag(resp.data);
				this.showFooter = true;
			}
		});
	}

	private removeHTMLTag(data: any): any {
		if (data.facebook) {
			this.footer.facebook = data.facebook.replace(/(<([^>]+)>)/gi, "");
		}
		if (data.instagram) {
			this.footer.instagram = data.instagram.replace(/(<([^>]+)>)/gi, "");
		}
	}

	private loadFooterContactUs() {
		this.cmsService.getContactUs().subscribe((resp) => {
			if (resp.data) {
				this.contactUs = resp.data;
				this.showcontacUs = true;
			}
		});
	}
}
