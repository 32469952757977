import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { PayFastUseCase } from "src/app/useCases/payfast.usecase";
import { Router } from "@angular/router";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { ToastrService } from "ngx-toastr";
import { SongsService } from "src/app/services/songs/songs.service";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationService } from "src/app/services/navigation/navigation.service";
import { BehaviorSubject, Observable } from "rxjs";

import { TrackTableConfig } from "../../components/track-table/track-table.component";
import { UserContentService } from "src/app/services/user/content/user-content.service";

@Component({
	selector: "app-user-cart",
	templateUrl: "./user-cart.component.html",
	styleUrls: ["./user-cart.component.scss"],
})
export class UserCartComponent implements OnInit {
	@ViewChild("desktopSidenav", { static: true }) desktopSidenav: MatSidenav;

	tableConfig: TrackTableConfig = {
		tracksTableMetaData: false,
		paginationControls: false,
		licenceSelect: false,
		trackControls: "list",
	};

	userCartForm: FormGroup;
	songs$: BehaviorSubject<RecordViewModel[]> = new BehaviorSubject([]);
	cart$: Observable<RecordViewModel[]>;

	cartCount = this.userContentService.cartCount$;

	song: any;
	isSelected = false;
	playlist: SafeUrl;

	faTimes = faTimes;

	p = 1;
	size = 0;

	msaapDisplayTitle = true;
	msaapDisplayPlayList = true;
	msbapDisplayTitle = true;
	msaapPageSizeOptions = [2, 4, 6];
	msaapDisplayVolumeControls = true;
	msbapDisplayVolumeControls = true;

	constructor(
		private formBuilder: FormBuilder,
		private userContentService: UserContentService,
		private spinner: NgxSpinnerService,
		private payFastUseCase: PayFastUseCase,
		private router: Router,
		private playTrackUseCase: PlayTrackUseCase,
		private sanitizer: DomSanitizer,
		private toast: ToastrService,
		private songsService: SongsService,
		private navigationService: NavigationService
	) {
		this.createFormBuild();
	}

	ngOnInit() {
		this.loadUserCarts();
	}

	checkout() {
		this.navigationService.dismissAll();
		this.payFastUseCase.saveCurrentUrl("cart");
		// this.userContentService.addRecordsToBasket();
		this.router.navigateByUrl("/checkout");
	}

	removeFromCart(song: any) {
		this.userContentService.removeFromCart(song).then((success: any) => {
			if (success) {
				this.toast.success("Item removed from cart");
			} else {
				this.toast.error(
					"Item could not be removed from cart, please contact the administrator"
				);
			}
		});
	}

	private loadUserCarts() {
		this.spinner.show();
		this.cart$ = this.userContentService.getUserCartSongs();
		this.cart$.subscribe(() => this.spinner.hide());
	}

	createFormBuild() {
		this.userCartForm = this.formBuilder.group({
			searchStr: ["", Validators.required],
			composer: [""],
			genre: [""],
			instrument: [""],
			mood: [""],
			tempo: [""],
			price: [""],
		});
	}
}
