import { Injectable } from '@angular/core';

import { UserGateway } from '../gateways/user.gateways';
import { EditProfileViewModel } from '../viewModels/edit-profile.viewmodel';

@Injectable()
export class EditProfileUseCase {
    constructor(private userGateway: UserGateway) { }

    private userProfile: EditProfileViewModel;

    saveUserProfile(profile: EditProfileViewModel) {
        this.userProfile = profile;
    }

    getSavedProfile(): EditProfileViewModel {
        return this.userProfile;
    }

    async updateProfile(profile: EditProfileViewModel, callback): Promise<any> {
        this.userGateway.updateProfile(profile, callback);
    }
}
