import { Component, OnInit } from "@angular/core";
import { PayFastUseCase } from "src/app/useCases/payfast.usecase";

@Component({
	selector: "app-checkout-failed",
	templateUrl: "./checkout-failed.component.html",
	styleUrls: ["./checkout-failed.component.scss"],
})
export class CheckoutFailedComponent implements OnInit {
	constructor(private payFastUseCase: PayFastUseCase) {
		// console.log("failed construction finished faile");
	}

	ngOnInit() {
		this.payFastUseCase.updateFailedUserPayment();
	}
}
