import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { PlaylistGateaway } from "src/app/gateways/playlist.gateways";
import { UserGateway } from "src/app/gateways/user.gateways";
import { CueSheetsViewModel } from "src/app/viewModels/cuesheets.viewmodel";
import { PlaylistViewModel } from "src/app/viewModels/playlist.viewmodel";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { AuthService } from "../../security/auth-service";

interface CartItem {
  song: RecordViewModel;
  licence: PriceViewModel;
}

@Injectable({
  providedIn: "root",
})
export class UserContentService {
  // @MM Cart Attributes
  public cartCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  private userCart$: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  private userCartSongs$: BehaviorSubject<RecordViewModel[]> =
    new BehaviorSubject([]);
  // END

  // @MM Order Attributes
  private userOrders$: BehaviorSubject<any[]> = new BehaviorSubject([]); // TODO: Create a order view model
  // END

  // @MM CueSheets Attributes
  private userCueSheets$: BehaviorSubject<Map<string, CueSheetsViewModel[]>> =
    new BehaviorSubject(null);
  private storedCueSheet: CueSheetsViewModel[] = null;
  private storedCueSheetType: string = null;
  // END

  // @MM Playlist Attributes
  private userPlaylist$: BehaviorSubject<RecordViewModel[]> =
    new BehaviorSubject([]);
  private storedPlaylist: PlaylistViewModel = null;
  // END

  //@MM Payment Attributes
  public userHasPayment$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentUserHasPayment = this.userHasPayment$.asObservable();
  //END

  constructor(
    private userGateway: UserGateway,
    private playListGateway: PlaylistGateaway,
    private authService: AuthService
  ) {
    this.userCartSongs$.subscribe((cart) => {
      this.cartCount$.next(cart.length);
      const oldRecords = this.userCart$.getValue();
      const newRecords = cart.map((song) => {
        let index = oldRecords.findIndex(
          (record) => record.song.songId == song.songId
        );
        return {
          song: song,
          licence: index > -1 ? oldRecords[index].licence : null,
        };
      });
      this.userCart$.next(newRecords);
    });
  }

  private groupBy = (list, keyGetter) => {
    const map = new Map<string, any[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  public async removeFromCart(song: any): Promise<boolean> {
    try {
      if (!this.authService.loggedIn()) return false;

      return await this.userGateway.removeFromCart(song).toPromise();
    } catch (error) {
      console.error('Error removing from cart:', error);
      return false;
    }
  }

  public async addToCart(recordViewModel: RecordViewModel): Promise<boolean> {
    if (!this.authService.loggedIn()) return false;

    try {
      const res = await this.userGateway.addToCart(recordViewModel).toPromise();
      if (res.data) {
        this.getUserCartSongs();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      return false;
    }
  }

  public getUserCartSongs(): Observable<any> {
    if (this.authService.loggedIn())
      this.userGateway.getUserCarts().subscribe((res) => {
        this.userCartSongs$.next(res);
      });
    return this.userCartSongs$;
  }

  public getUserCart(): Observable<any> {
    this.getUserCartSongs();
    return this.userCart$;
  }

  public selectCartItemLicence(song: RecordViewModel, licence: PriceViewModel) {
    this.userCart$.next(
      this.userCart$.getValue().map((record) => {
        if (record.song.songId == song.songId) record.licence = licence;
        return record;
      })
    );
  }

  async deleteCuesheet(id: string): Promise<boolean> {
    let res = await this.userGateway.deleteCuesheet(id).toPromise();
    return res;
  }

  public async removeAllFromCarts(): Promise<boolean> {
    if (!this.authService.loggedIn()) return false;
    let res = await this.userGateway.removeAllFromCarts().toPromise();
    return res.data;
  }

  public async checkIfPaymentWasMade(): Promise<boolean> {
    if (!this.authService.loggedIn()) return false;
    let res = await this.userGateway.checkIfPaymentWasMade().toPromise();
    return res.data ? true : false;
  }
  // END

  // @MM Order Methods
  public getUserOrders(): Observable<any[]> {
    if (this.authService.loggedIn())
      this.userGateway.getUserOrders().subscribe((res) => {
        if (res && res.length > 0) this.userOrders$.next(res);
      });
    return this.userOrders$;
  }
  //END

  // @MM CueSheets Methods
  public async saveCueSheet(cueSheets: CueSheetsViewModel[]): Promise<any[]> {
    let saveOne = async (cueSheet) => {
      return this.userGateway.saveCueSheet(cueSheet).toPromise();
    };
    return Promise.all(cueSheets.map((cueSheet) => saveOne(cueSheet)));
  }
  public storeCueSheet(cueSheet: CueSheetsViewModel[]): void {
    this.storedCueSheet = cueSheet;
  }
  public getStoredCueSheet(): CueSheetsViewModel[] {
    return this.storedCueSheet;
  }
  public storeCueSheetType(type: string): void {
    this.storedCueSheetType = type;
  }
  public getStoredCueSheetType(): string {
    return this.storedCueSheetType;
  }
  public getUserCueSheets(): Observable<Map<string, CueSheetsViewModel[]>> {
    this.userGateway.getUserCuesheet().subscribe((res) => {
      if (res)
      this.userCueSheets$.next(
        this.groupBy(res, (cuesheet) => cuesheet.cueSheetName)
      );
    });
    return this.userCueSheets$;
  }
  // END

  public getPlaylist(playlistId: string): any {
    return this.playListGateway.getPlaylist(playlistId);
  }

  // @MM Playlist Methods
  public addToPlaylist(recordViewModel: RecordViewModel): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.authService.loggedIn()) {
        resolve(false);
      }

      this.userGateway.addRecordToPlaylist(recordViewModel).subscribe((resp) => {
        if (resp) {
          this.getUserPlaylist();
          resolve(true);
        } else {
          resolve(false);
        }
      },
        (error) => {
          console.error('An error occured:', error);
          resolve(false);
        }
      );
    });
  }

  public getUserPlaylist(): Observable<RecordViewModel[]> {
    if (this.authService.loggedIn())
      this.userGateway.getUserPlaylist().subscribe((resp) => {
        if (resp.length) this.userPlaylist$.next(resp);
      });
    return this.userPlaylist$;
  }

  public async removeSongFromPlaylist(song: RecordViewModel): Promise<boolean> {
    try {
      if (!this.authService.loggedIn()) return false;

      return await this.userGateway.removeSongFromPlaylist(song).toPromise();
    } catch (error) {
      console.error('Error removing from cart:', error);
      return false;
    }
  }
  public songIsFavourite(song: RecordViewModel): boolean {
    if (song === null || song === undefined) {
      return;
    }
    let index = this.userPlaylist$
      .getValue()
      .findIndex((record) => record.songId == song.songId);
    return index > -1;
  }
  // END

  //UserPayment;
  public userHasPayment() {
    return this.userGateway.userHasPayment().subscribe((resp) => {
      this.userHasPayment$.next(resp);
    });
  }
  //END
}
