import { Component, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/security/auth-service";
import { NgxSpinnerService } from "ngx-spinner";

import { LoginUsecase } from "src/app/useCases/login.usecase";
import { ModalService } from "src/app/utils/modal";
import { touchAllFormFields } from "src/app/utils/validation";
import { UserContentService } from "src/app/services/user/content/user-content.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  loginForm: FormGroup;
  @Input() loginMessage = "LOGIN";

  constructor(
    private loginUsecase: LoginUsecase,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private userContentService: UserContentService,
  ) {
    this.createFormBuild();
  }

  async login(): Promise<void> {
    if (!this.loginForm.valid) {
      touchAllFormFields(this.loginForm);
      return;
    }
    this.spinner.show();
    this.loginUsecase
      .login(this.loginForm.value)
      .then((resp) => {
        if (resp.user) {
          this.toast.success("Logged in successfully");
          this.spinner.hide();
          this.closeModal();
          this.router.navigateByUrl("/home");
          this.userHasPayment();
        } else {
          this.toast.error("Incorrect credentials");
          this.spinner.hide();
        }
      })
      .catch((error) => {
        this.toast.error(error);
        this.spinner.hide();
      });
  }

  close() {
    this.modalService.close("login");
  }

  navigateTo() {
    this.close();
    this.signUp();
  }

  signUp() {
    const id = "sign-up";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  }

    private userHasPayment() {
      this.userContentService.userHasPayment();
  }

  private closeModal() {
    if (this.router.url === "/home/login") {
      return;
    }
    this.close();
  }

  private createFormBuild() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }
}
