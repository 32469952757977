import { Injectable } from "@angular/core";
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from "@angular/router";
import { ModalService } from "src/app/utils/modal";
import { AuthService } from "./auth-service";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private modalService: ModalService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (this.authService.loggedIn()) {
			return true;
		} else {
			return this.navigateToLoginUrl();
		}
	}

	private navigateToLoginUrl() {
		const id = "login";
		localStorage.clear();
		this.modalService.add(id);
		this.modalService.open(id);
		return false;
	}
}
