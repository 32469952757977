import { Component, OnInit } from "@angular/core";
import { NotifyViewModel } from "src/app/viewModels/notify-viewmodel";
import { NotifyTransactionUseCase } from "src/app/useCases/notify-transaction.usecase";

@Component({
	selector: "app-notify-transaction",
	templateUrl: "./notify-transaction.component.html",
	styleUrls: ["./notify-transaction.component.css"],
})
export class NotifyTransactionComponent implements OnInit {
	paymentTransaction: NotifyViewModel;

	constructor(private notifyTransactionUseCase: NotifyTransactionUseCase) {}

	ngOnInit() {
		this.notifyTransactionUseCase.get().subscribe((resp) => {
			//   console.log('response from payment', resp);
		});
	}

	itn(paymentTransaction) {
		// console.log('This is a returned value from payfast', paymentTransaction);
	}
}
