import { Component, OnInit } from '@angular/core';
import { UserContentService } from 'src/app/services/user/content/user-content.service';
import { LogoutUsecase } from 'src/app/useCases/logout.usecase';

@Component({
  selector: 'app-logout',
   templateUrl: './logout.component.html',
   styleUrls: []
})
export class LogoutComponent implements OnInit {

  constructor(private logoutUsecase: LogoutUsecase, private userContentService: UserContentService) { }
 
  ngOnInit() {
    this.logoutUsecase.logout();
    this.userContentService.userHasPayment$.next(false);
  }
}
