import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserGateway } from '../gateways/user.gateways';

@Injectable()
export class ViewProfileUseCase {
    constructor(private userGateway: UserGateway) { }

    getUserProfile(): Observable<any> {
        return this.userGateway.getUserProfile();
    }
}
