import { Injectable } from "@angular/core";
import { MatDialog, MatDialogState } from "@angular/material/dialog";
import { ComponentType } from "ngx-toastr";
import { EnquiryComponent } from "src/app/pages/enquiry/enquiry.component";
import { SignUpComponent } from "src/app/pages/sign-up/sign-up.component";
import { LoginComponent } from "src/app/sections/login/login.component";

@Injectable({ providedIn: "root" })
export class ModalService {
	private modals: { [id: string]: ComponentType<any> } = {};

	constructor(private matDialog: MatDialog) {}

	add(modal: any) {
		let component = this.findComponent(modal as string);
		if (component) this.modals[modal as string] = component;
	}
	private findComponent(id: string) {
		switch (id) {
			case "login":
				return LoginComponent;
			case "sign-up":
				return SignUpComponent;
			case "enquiry":
				return EnquiryComponent;
			default:
				break;
		}
	}

	remove(id: string) {
		if (Object.keys(this.modals).includes(id)) delete this.modals[id];
	}

	open(id: string, options?: { [key: string]: any }) {
		let component = this.modals[id];
		if (component) {
			const existing = this.matDialog.getDialogById(id);
			if (existing && existing.getState() == MatDialogState.OPEN) {
				existing.close();
				existing
					.afterClosed()
					.toPromise()
					.then(() =>
						this.matDialog.open(component, { id: id, ...options })
					);
			} else this.matDialog.open(component, { id: id, ...options });
		}
	}

	close(id: string) {
		this.matDialog.getDialogById(id).close();
	}
}
