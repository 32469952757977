import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackGateway } from '../gateways/track.gateways';

@Injectable()
export class GetPlaylistUseCase {
    constructor(private trackGateway: TrackGateway) { }

    get(): Observable<any[]> {
        return this.trackGateway.getPlaylist();
    }
}
