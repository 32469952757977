export class PaymentViewModel {
    nameFirst = '';
    nameLast = '';
    emailAddress = '';
    amount = '';
    itemName = '';
    itemDescription = '';
    paymentMethod = '';
    paymentId = '';
}
