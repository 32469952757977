import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { Observable, of } from "rxjs";

@Component({
	selector: "app-playlist-tracks",
	templateUrl: "./playlist-tracks.component.html",
	styleUrls: ["./playlist-tracks.component.scss"],
})
export class PlaylistTracksComponent implements OnInit {
	playlist: any = { tracks: [] };
	playlistId: any;

	constructor(
		private userContentService: UserContentService,
		private activatedroute: ActivatedRoute,
		private router: Router
	) {
		this.playlistId = this.activatedroute.snapshot.paramMap.get("playlistId");
		this.activatedroute.paramMap.subscribe(paramMap => {
			this.playlistId = parseInt(paramMap.get('playlistId'));
		})
	}

	ngOnInit() {
		this.userContentService.getPlaylist(this.playlistId).subscribe((playlist) => {
			if (!playlist.tracks.length) {
				return this.router.navigateByUrl("/home/music-library/playlist");
			}

			for (let i = 0; i < playlist.tracks.length; i++) {
				this.playlist.genre = playlist.tracks[i].Genre;
				this.playlist.tracks.push({
					fileName: playlist.tracks[i].Title,
					songTitle: playlist.tracks[i].Title,
					songId: playlist.tracks[i]['SourceAudio ID']
				});
			}
			this.playlist.name = playlist.name;
		});
	}

	public getTracks(): Observable<RecordViewModel[]> {
		return of(this.playlist.tracks);
	}
}
