import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGateway } from '../gateways/user.gateways';
import { EnquiryViewModel } from '../viewModels/enquiry.viewmodel';

@Injectable({
  providedIn: 'root'
})
export class EnquiryUsecase {

  constructor(private userGateway: UserGateway) { }

  enquire(enquiry: EnquiryViewModel): Promise<any> {
    return this.userGateway.userEnquiry(enquiry);
  }
}
