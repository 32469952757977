import { Overlay } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";

import { TrackGateway } from "src/app/gateways/track.gateways";
import { ModalService } from "../utils/modal";
import { RecordViewModel } from "../viewModels/record.viewmodel";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class PlayTrackUseCase {
  public currentlyPlaying: BehaviorSubject<RecordViewModel> =
    new BehaviorSubject(null);

  constructor(
    private trackGateway: TrackGateway,
    private modalService: ModalService,
    private overlay: Overlay
  ) {}

  playSong(song: RecordViewModel) {
    this.currentlyPlaying.next(song);
  }
  public async downloadSong(song: RecordViewModel): Promise<boolean> {
    let resp = await this.trackGateway.playSong(song.songId).toPromise();
    if (resp.fullPath) {
      const data = resp.fullPath;

      const response = await fetch(data);
      const blob = await response.blob();
      const blobWithType = new Blob([blob], { type: "audio/mp3" });
      
      saveAs(blobWithType, song.fileName);

      return true;
    }
    return false;
  }
  public async loadSongUrl(song: RecordViewModel): Promise<SafeUrl> {
    let resp = await this.trackGateway.playSong(song.songId).toPromise();
    if (resp) {
      const songUrl = resp.fullPath;
      return songUrl;
    }
    return null;
  }
  private createBufferArray(resp: any) {
    const data = "data:audio/mp3;base64," + resp;
    const BASE64_MARKER = ";base64,";
    const base64Index = data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = data.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
