import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SignUpUsecase } from "src/app/useCases/sign-up.usecase";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { touchAllFormFields } from "src/app/utils/validation";
import { MustMatch } from "src/app/utils/passwordMatch";
import { ModalService } from "src/app/utils/modal";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent {
  signUpForm: FormGroup;

  constructor(
    private signUpUsecase: SignUpUsecase,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: ModalService
  ) {
    this.createFormBuild();
  }

  async signUp(): Promise<void> {
    if (!this.signUpForm.valid) {
      touchAllFormFields(this.signUpForm);
      return;
    }
    this.spinner.show();
    this.signUpUsecase
      .signUp(this.signUpForm.value)
      .then((resp) => {
        if (resp.user) {
          this.spinner.hide();
          this.navigateTo();
          this.toast.success("Signed in successfully, Please log in");
        } else {
          this.toast.error(
            "Signed in failed, please contact your administrator"
          );
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.toast.error(error);
      });
  }

  cancel() {
    return this.router.navigateByUrl("/home");
  }

  navigateTo() {
    this.close();
    this.login();
  }

  login() {
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  }

  private createFormBuild() {
    this.signUpForm = this.formBuilder.group(
      {
        name: ["", Validators.required],
        surname: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  close() {
    this.modalService.close("sign-up");
  }
}
