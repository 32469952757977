import { Component, Input, OnInit } from "@angular/core";
import { toInteger } from "lodash";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";

@Component({
	selector: "app-price-cards",
	templateUrl: "./price-cards.component.html",
	styleUrls: ["./price-cards.component.scss"],
})
export class PriceCardsComponent implements OnInit {
	public prices$: BehaviorSubject<
		Map<string, PriceViewModel[]>
	> = new BehaviorSubject(null);
	public savedSelect: { [key: number]: number } = {};

	constructor(private priceUseCase: PriceUseCase) {}

	ngOnInit(): void {
		this.priceUseCase.getPrices().subscribe((prices) => {
			if (
				!this.prices$.getValue() ||
				this.prices$.getValue()?.size == 0
			) {
				let priceMap = this.priceUseCase.getGroupedPrices(
					(price) => price.name
				);
				let index = 0;
				priceMap.forEach((value, key) => {
					if (value.length > 1) {
						value = this.sort(value);
						this.savedSelect[index++] = 0;
					}
				});
				this.prices$.next(priceMap);
			}
		});
	}

	sort(prices: PriceViewModel[]) {
		return prices.sort((a, b) => toInteger(a.price) - toInteger(b.price));
	}

	public saveSelectChange(i: number, option: number) {
		this.savedSelect[i] = option;
	}
}
