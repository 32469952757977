import { Component, OnInit, ViewChild } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormControl,
	AbstractControl,
} from "@angular/forms";
import { PaymentViewModel } from "src/app/viewModels/payment.viewmodel";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { PayFastUseCase } from "src/app/useCases/payfast.usecase";
import { TrackTableConfig } from "../../components/track-table/track-table.component";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { BehaviorSubject, Observable } from "rxjs";
import { toInteger } from "lodash";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { CdkStep, CdkStepper } from "@angular/cdk/stepper";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";

interface ORDER {
	song: RecordViewModel;
	licence: any;
}
@Component({
	selector: "app-checkout",
	templateUrl: "./checkout.component.html",
	styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
	public trackConfig: TrackTableConfig = {
		tracksTableMetaData: false,
		paginationControls: false,
		licenceSelect: false,
		trackControls: "list",
	};

	public prices$: BehaviorSubject<
		Map<string, PriceViewModel[]>
	> = new BehaviorSubject(null);
	public checkoutForm: FormGroup;

	@ViewChild("licenceStep") licenceStep: CdkStep;
	@ViewChild("paymentStep") paymentStep: CdkStep;
	@ViewChild("checkoutStepper") checkoutStepper: CdkStepper;

	constructor(
		private formBuilder: FormBuilder,
		private userContentService: UserContentService,
		private priceUseCase: PriceUseCase,
		private payFastUseCase: PayFastUseCase
	) {
		this.priceUseCase.getPrices().subscribe(() => {
			this.prices$.next(
				this.priceUseCase.getGroupedPrices((price) => price.name)
			);
		});
	}
	get f() {
		return this.checkoutForm.controls;
	}
	get t() {
		return this.f.orders as FormArray;
	}
	set t(orders: FormArray) {
		this.f.orders = orders;
	}

	ngOnInit() {
		this.createFormBuild();
	}

	ngAfterViewInit() {
		this.userContentService.getUserCart().subscribe((cartRecords) => {
			this.checkoutForm.setControl(
				"orders",
				new FormArray(
					cartRecords.map((record, index) => {
						return this.formBuilder.group({
							licence: [
								record.licence ? record.licence : "",
								Validators.required,
							],
							song: record.song,
						});
					})
				)
			);
		});
	}

	public payFast() {
		let payment: PaymentViewModel = new PaymentViewModel();
		payment.amount = this.checkoutForm.get("total").value;
		let songs = [];
		for (let index = 0; index < this.t.length; index++) {
			const order = this.t.at(index);
			const licence = order.get("licence").value;
			const song = order.get("song").value;
			songs.push({
				...song,
				price: licence.price,
				priceId: licence.key,
			});
		}
		this.payFastUseCase.checkout(payment, songs);
	}

	public calculateTotal(order: FormControl) {
		let total: number = 0;
		for (let i = 0; i < this.t.length; i++) {
			let order = this.t.get([i, "licence"]);
			if (order.valid) total += toInteger(order.value.price);
		}
		this.f.total.setValue(total);
	}

	private createFormBuild() {
		this.checkoutForm = this.formBuilder.group(
			{
				orders: new FormArray([]),
				total: ["", [Validators.required]],
			},
			{
				validators: [
					(control: AbstractControl) => {
						const orders: any[] = control.get("orders").value;
						if (orders.length == 0)
							return { checkoutError: "Waiting for data..." };
						const total = orders
							.map((order) =>
								toInteger(order["licence"]["price"])
							)
							.reduce((a, b) => a + b);
						if (control.get("total").value !== total)
							return {
								checkoutError:
									"Please confirm a licence for each track.",
							};
					},
				],
			}
		);
	}
}
