import { Component } from "@angular/core";
import MapStyle from "./map.style";

@Component({
	selector: "app-map",
	templateUrl: "./map.component.html",
	styleUrls: ["./map.component.scss"],
})
export class MapComponent {
	constructor() {}

	public center: google.maps.LatLngLiteral = {
		lat: -26.0992652,
		lng: 27.9876122,
	};
	public style: google.maps.MapTypeStyle[] = MapStyle;
	public iconUrl = "/assets/images/mapMarker.svg";
	public options: google.maps.MapOptions = {
		center: this.center,
		backgroundColor: "#5b5b5b",
		styles: this.style,
		zoom: 16,
		disableDefaultUI: true,
	};
	private markerIcon: google.maps.Icon = {
		url: this.iconUrl,
	};
	public markerOptions: google.maps.MarkerOptions = {
		position: this.center,
		icon: this.markerIcon,
	};
	public markerOnClick = () => {
		window.open(
			"https://www.google.com/maps/place/Urban+Brew+Studios/@-26.1048686,27.9910764,17z/data=!3m1!4b1!4m5!3m4!1s0x1e9574944f12c813:0x856eec2a46d77306!8m2!3d-26.1048686!4d27.9910764?hl=en",
			"_blank"
		);
	};
}
