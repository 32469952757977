import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { SearchGateway } from "src/app/gateways/search.gateways";
import { TrackGateway } from "src/app/gateways/track.gateways";
import { toInteger } from "lodash";

@Injectable({
	providedIn: "root",
})
export class SongsService {
	constructor(
		private searchGateway: SearchGateway,
		private trackGateway: TrackGateway
	) {}

	private songs: RecordViewModel[] = [];
	private filters: [string, string][] = [];
	private sorts: { [key: string]: boolean } = {
		newest: false,
		"most popular": false,
	};

	public songs$: BehaviorSubject<RecordViewModel[]> = new BehaviorSubject(
		this.songs
	);
	public filters$: BehaviorSubject<string[]> = new BehaviorSubject(
		this.filters.map((filter) => filter[1])
	);
	public sorts$: BehaviorSubject<{
		[key: string]: boolean;
	}> = new BehaviorSubject(this.sorts);

	public filterSelected: boolean = false;
	public currentSearch: string = "";

	private updateSongs(songs: RecordViewModel[]) {
		if (this.filterSelected) {
			const filteredSongs = songs.filter(song =>
				this.filters.every(([key, value]) => {
					const pascalKey = key.replace(/\w+/g,
					function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});

					const pascalValue = value.replace(/\w+/g,
					function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
					
					const songValue = song[pascalKey];
					if (songValue === undefined || songValue === null) {
						return false;
					}
					return songValue.split("/").includes(pascalValue);
				})
			);
			this.songs$.next(filteredSongs);
		} else {
			this.songs$.next(songs);
		}
	}

	private updateFilters() {
		this.filterSelected = this.filters.length > 0;
		this.filters$.next(this.filters.map((filter) => filter[1]));
		this.updateSongs(this.songs);
	}

	public search(searchStr: string): boolean {
		this.currentSearch = searchStr;
		this.searchGateway.search(searchStr.toLowerCase()).subscribe(
			(result) => {
				result.tracks.forEach((track: any) => {
				track.songTitle = track.Title;
				track.fileName = track.Title;
				track.songId = track['SourceAudio ID'],
				track.composer = track.Label;
				});
				this.songs = [...result.tracks];
				this.updateSongs(this.songs);
			},
			(err) => {
				console.error(err);
			}
		);
		return true;
	}

	public sort(by: string) {
		try {
			this.sorts[by] = !this.sorts[by];
		} catch {
			this.sorts[by] = true;
		}
		Object.keys(this.sorts).map((key) => {
			if (this.sorts[key]) this.applySort(key);
			else this.revokeSort(key);
		});
		this.sorts$.next(this.sorts);
	}
	private revokeSort(by: string) {
		switch (by) {
			case "newest":
				this.updateSongs(
					this.songs$
						.getValue()
						.sort(
							(a: RecordViewModel, b: RecordViewModel) =>
								toInteger(a.songId) - toInteger(b.songId)
						)
				);
				break;
			case "most popular":
				this.updateSongs(this.songs);
				break;
			default:
				break;
		}
	}
	private applySort(by: string) {
		switch (by) {
			case "newest":
				this.updateSongs(
					this.songs$.getValue().sort((a, b) => {
						return (
							new Date(a.yearComposed).getTime() -
							new Date(b.yearComposed).getTime()
						);
					})
				);
				break;
			case "most popular":
				this.loadPopularTracks();
				// Object.keys(this.sorts).map(
				// 	(key) => (this.sorts[key] = key == by)
				// );
				break;
			default:
				break;
		}
	}
	// @MM This filter method needs a rework - DONE
	public addFilter(category: string, filter: string) {
		if (
			this.filters.findIndex(
				(el) => el[0] == category && el[1] == filter
			) < 0
		) {
			this.filters.push([category, filter]);
			this.updateFilters();
		}
	}

	public loadPopularTracks() {
		this.trackGateway.getPopularTracks().subscribe(
			(result) => {
				// this.songs = [...result];
				// console.log(result);
				this.updateSongs(result);
			},
			(err) => {
				console.error(err);
			}
		);
	}

	public clearFilter(filter: string) {
		this.filters = this.filters.filter((el) => {
			return el[1] != filter;
		});
		this.updateFilters();
	}
}
