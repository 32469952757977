import { Injectable } from '@angular/core';

import { UserGateway } from '../gateways/user.gateways';

@Injectable({
  providedIn: 'root'
})
export class LogoutUsecase {

  constructor(private userGateway: UserGateway) { }

  logout() {
    return this.userGateway.logout();
  }
}
