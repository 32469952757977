import { MatSidenav } from "@angular/material/sidenav";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { LoginComponent } from "src/app/sections/login/login.component";

@Injectable({
	providedIn: "root",
})
export class NavigationService {
	public desktopSidenav: MatSidenav;
	public mobileSidenav: MatSidenav;
	public mobileBottomSheet: MatBottomSheet;

	constructor(private matDialog: MatDialog) {}

	openLoginModal(): Observable<any> {
		return this.matDialog.open(LoginComponent).afterClosed();
	}

	public dismissAll() {
		if (this.mobileBottomSheet) this.mobileBottomSheet.dismiss();
		if (this.mobileSidenav) this.mobileSidenav.close();
		if (this.desktopSidenav) this.desktopSidenav.close();
	}
}
