import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";

import { PriceViewModel } from "../viewModels/price.viewmodel";

@Injectable({
  providedIn: "root",
})
export class PriceGateway {
  pricecollection: AngularFirestoreCollection<PriceViewModel>;

  prices: Observable<PriceViewModel[]>;

  constructor(private fdb: AngularFirestore) {
    this.pricecollection = this.fdb.collection("Price");
  }

  getPrices(): Observable<any> {
    return (this.prices = this.pricecollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as PriceViewModel;
          data.key = a.payload.doc.id;
          return data;
        });
      })
    ));
  }
}
