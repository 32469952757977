import { Injectable } from '@angular/core';
import { CheckoutGateway } from '../gateways/checkout.gateways';

@Injectable()
export class NotifyTransactionUseCase {
    constructor(private  checkoutGateway: CheckoutGateway) { }

    get() {
        return this.checkoutGateway.checkoutStatus();
    }
}
