import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { BehaviorSubject, Observable } from "rxjs";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import {
  faHeart,
  faCartArrowDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { AuthService } from "src/app/services/security/auth-service";
import { ModalService } from "src/app/utils/modal";
import { ToastrService } from "ngx-toastr";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { SongsService } from "src/app/services/songs/songs.service";

export interface TrackTableConfig {
  tracksTableMetaData?: boolean;
  paginationControls?: boolean;
  licenceSelect?: boolean;
  trackControls?: "view" | "list" | "download" | "extend";
}

@Component({
  selector: "app-user-playlist",
  templateUrl: "./user-playlist.component.html",
  styleUrls: ["./user-playlist.component.scss"],
})
export class UserPlaylistComponent implements OnInit {
  @Input("config") config: TrackTableConfig = {
    tracksTableMetaData: true,
    paginationControls: true,
    licenceSelect: true,
    trackControls: "view",
  };

  songs$: Observable<RecordViewModel[]>;

  userPlaylistForm: FormGroup;
  playlist: RecordViewModel[];
  public prices$: BehaviorSubject<Map<string, PriceViewModel[]>> =
    new BehaviorSubject(null);

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faHeart = faHeart;
  faCartArrowDown = faCartArrowDown;
  public isFavourite = false;
  showDialog = false;
  p = 1;
  size = 0;
  pages = 1;

  song: any = new RecordViewModel();
  isSelected = false;
  playlists: SafeUrl;

  composer: string;
  filters = {};
  types = [];
  songs = [];

  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  msbapDisplayTitle = true;
  msaapPageSizeOptions = [2, 4, 6];
  msaapDisplayVolumeControls = true;
  msbapDisplayVolumeControls = true;

  constructor(
    private formBuilder: FormBuilder,
    private userContentService: UserContentService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private playTrackUseCase: PlayTrackUseCase,
    private authServ: AuthService,
    private modalService: ModalService,
    private toast: ToastrService,
    private priceUseCase: PriceUseCase,
    private songService: SongsService
  ) {
    this.priceUseCase.getPrices().subscribe((prices) => {
      if (!this.prices$.getValue() || this.prices$.getValue()?.size == 0)
        this.prices$.next(
          this.priceUseCase.getGroupedPrices((price) => price.name)
        );
    });
    this.createFormBuild();
  }

  ngOnInit() {
    this.songs.length = 0;
    this.userContentService.getUserPlaylist().subscribe((resp) => {
      if (resp.length) {
        this.songs = resp;
        for (let i = 0; i < resp.length; i++) {
          const song = resp[i];
          this.isFavourite = this.userContentService.songIsFavourite(song);
        }
      }
    });
  }

  SelectedPrice() {
    const licence = this.userPlaylistForm.controls.price.value;
  }

  public playSong(song: RecordViewModel) {
    this.playTrackUseCase.playSong(song);
  }

  removeFromPlaylist(song: any, index: number) {
    if (song.userId) {
      this.userContentService.removeSongFromPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " removed from your favourites."
          );
          this.songs.splice(index, 1);
        } else {
          this.toast.error("user playlist was not removed, Please contact admin");
        }
      });
    } else {
      this.songs.splice(index, 1);
    }
  }

  addToCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToCart(song).then((resp) => {
        if (resp) {
          this.toast.success(
            song.fileName.toUpperCase() + " added in your cart."
          );
        } else {
          this.toast.error(
            "Item could not be added into your cart, probably it already existing, please contact the administrator"
          );
        }
      });
    } else {
      const id = "login";
      this.modalService.add(id);
      this.openModal();
    }
  }

  removeFromCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.removeFromCart(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(title.toUpperCase() + " removed from your cart.");
        } else {
          this.toast.error(
            "Item could not be removed from your cart, please contact the administrator"
          );
        }
      });
    }
  }

  public selectPrice(song: RecordViewModel, price: PriceViewModel) {
    this.userContentService.selectCartItemLicence(song, price);
  }

  public addFilter(composer: string) {
    if (composer) this.songService.addFilter("composer", composer);
  }

  addToPlaylist(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " added to your favourites."
          );
        } else {
          this.toast.error(
            "Item could not be added to your favourites, probably it already existing, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }

  private openModal() {
    this.showDialog = true;
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
  }

  createFormBuild() {
    this.userPlaylistForm = this.formBuilder.group({
      searchStr: ["", Validators.required],
      composer: [""],
      genre: [""],
      instrument: [""],
      mood: [""],
      tempo: [""],
      price: [""],
    });
  }
}
