import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { GetPlaylistUseCase } from "src/app/useCases/get-playlist.usecase";
import { SongsService } from "src/app/services/songs/songs.service";
import { TrackTableConfig } from "src/app/components/track-table/track-table.component";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { GetPopularTracksUseCase } from "src/app/useCases/get-popular-tracks.usecase";
import { UserContentService } from "src/app/services/user/content/user-content.service";

@Component({
	selector: "app-music-library",
	templateUrl: "./music-library.component.html",
	styleUrls: ["./music-library.component.scss"],
})
export class MusicLibraryComponent implements OnInit {
	public config: TrackTableConfig = {
		tracksTableMetaData: false,
		paginationControls: true,
		licenceSelect: true,
		trackControls: "view",
	};
	popularTracks$: Observable<RecordViewModel[]>;
	prices$: Observable<PriceViewModel[]>;
	playlist: any[];
	isActive: true;
	isActivePage: true;
	itemsToDisplay = 10;

	constructor(
		private priceUseCase: PriceUseCase,
		private songsService: SongsService,
		private playlistUseCase: GetPopularTracksUseCase,
		private getPlaylistUseCase: GetPlaylistUseCase,
		private userContentService: UserContentService,
		private spinner: NgxSpinnerService,
		private router: Router
	) {
		this.prices$ = this.priceUseCase.getPrices();
		this.popularTracks$ = this.playlistUseCase.get();
	}

	ngOnInit(): void {}

	showTracks(playlist) {
		this.userContentService.addToPlaylist(playlist);
		this.router.navigateByUrl("home/music-library/playlist/playlist-tracks");
	}

	navigateTo(path: string) {
		const homeUrl = "/home";
		if (path) {
			const url = `${homeUrl}/${path}`;
			return this.router.navigateByUrl(url);
		}
		this.router.navigateByUrl("/home");
	}
}
