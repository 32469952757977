import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";

const AUTH_TOKEN = environment.source_audio_token;
const DOMAIN = environment.source_audio_domain;

@Injectable({
  providedIn: "root",
})
export class PlaylistGateaway {
  constructor(private http: HttpClient) {}

  getPlaylist(id: string): Observable<any> {
    const url = `${DOMAIN}/playlists/getById?playlist_id=${id}&token=${AUTH_TOKEN}`;
    return this.http.get(url);
  }
}
