import { NgModule } from "@angular/core";
import { HomeComponent } from "src/app/pages/home/home.component";
import { PricingComponent } from "src/app/pages/pricing/pricing.component";
import { AboutUsComponent } from "src/app/pages/about-us/about-us.component";
import { FaqComponent } from "src/app/pages/faq/faq.component";
import { ContactUsComponent } from "src/app/pages/contact-us/contact-us.component";
import { SearchComponent } from "src/app/pages/search/search.component";
import { SearchContainerComponent } from "src/app/pages/search/search-container/search-container.component";
import { TcComponent } from "src/app/pages/tc/tc.component";
import { StudioComponent } from "src/app/pages/studio/studio.component";
import { EnquiryComponent } from "src/app/pages/enquiry/enquiry.component";
import { SignUpComponent } from "src/app/pages/sign-up/sign-up.component";
import { PopularTracksComponent } from "src/app/pages/popular-tracks/popular-tracks.component";
import { PlaylistTracksComponent } from "src/app/pages/playlist-tracks/playlist-tracks.component";
import { PagesRoutingModule } from "./pages-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ModalModule } from "src/app/utils/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxAudioPlayerModule } from "ngx-audio-player";
import { ComponentsModule } from "../components/components.module";
import { MusicLibraryComponent } from "src/app/pages/music-library/music-library.component";
import { BrowsePlaylistsComponent } from "../../pages/browse-playlists/browse-playlists.component";
import { ComposersComponent } from "src/app/pages/composers/composers.component";
import { GetPlaylistUseCase } from "src/app/useCases/get-playlist.usecase";
import { PriceCardsComponent } from "../../sections/price-cards/price-cards.component";
import { HomeFooterComponent } from "src/app/sections/home-footer/home-footer.component";
import { LoginComponent } from "src/app/sections/login/login.component";
import { MusicSearchComponent } from "src/app/sections/music-search/music-search.component";
import { PlaylistComponent } from "src/app/sections/playlist/playlist.component";
import { PartnersComponent } from "../../sections/partners/partners.component";

@NgModule({
	declarations: [
		HomeComponent,
		PricingComponent,
		AboutUsComponent,
		FaqComponent,
		ContactUsComponent,
		SearchComponent,
		SearchContainerComponent,
		HomeFooterComponent,
		TcComponent,
		StudioComponent,
		EnquiryComponent,
		SignUpComponent,
		LoginComponent,
		PopularTracksComponent,
		PlaylistTracksComponent,
		PlaylistComponent,
		MusicLibraryComponent,
		MusicSearchComponent,
		BrowsePlaylistsComponent,
		ComposersComponent,
		PriceCardsComponent,
		PartnersComponent,
	],
	imports: [
		PagesRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		FontAwesomeModule,
		ModalModule,
		BsDropdownModule,
		NgxPaginationModule,
		NgxSpinnerModule,
		NgxAudioPlayerModule,
		ComponentsModule,
	],
	providers: [GetPlaylistUseCase],
})
export class PagesModule {}
