import {
  AfterViewInit,
  Input,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  faTrash,
  faPen,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";

import { CueSheetsViewModel } from "src/app/viewModels/cuesheets.viewmodel";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import * as _ from "lodash";

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: "app-cuesheets",
  templateUrl: "./cuesheets.component.html",
  styleUrls: ["./cuesheets.component.scss"],
})
export class CuesheetsComponent implements OnInit, AfterViewInit {
  @ViewChild("mailingAddress") address: any;
  createCuesheetForm: FormGroup;
  types = [];
  cuesheets = [];
  isEditing: boolean = false;
  isEditCurrentCuesheet: boolean = false;

  faPen = faPen;
  faTrash = faTrash;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  p = 1;
  pages = 1;

  constructor(
    private formBuilder: FormBuilder,
    private userContentService: UserContentService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toast: ToastrService
  ) {
    this.loadTypes();
    this.createFormBuild();
  }

  get f() {
    return this.createCuesheetForm.controls;
  }
  get productDetails() {
    return this.f.productDetails as FormGroup;
  }
  get contactDetails() {
    return this.f.contactDetails as FormGroup;
  }
  get cueDetails() {
    return this.f.cueDetails as FormGroup;
  }

  get isCueTitleAvailable() {
    let found: boolean = false;
    for (let i = 0; i < this.cuesheets.length; i++) {
      const element = this.cuesheets[i];
      if (element.cueTitle) {
        found = true;
      } else {
        found = false;
      }
    }

    if (found) return true;
    return false;
  }

  contactForm: FormGroup;
  cueForm: FormGroup;

  ngOnInit() {
    this.getCuesheets();
    this.cueDetails.get("trackId").valueChanges.subscribe((value) => {
      this.isEditing =
        this.cuesheets.findIndex((cuesheet) => cuesheet.trackId == value) >= 0;
    });
  }

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.address.nativeElement,
      {
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.contactDetails.get("address").patchValue(place.formatted_address);
    });
  }

  saveAndExit(callback) {
    this.spinner.show();
    if (!this.productDetails.get("cueSheetName").valid)
      return this.productDetails.get("cueSheetName").markAsTouched();

    if (!this.cuesheets.length) {
      this.cuesheets.push({
        ...this.productDetails.value,
        ...this.contactDetails.value,
      });
    }

    const cuesheets: CueSheetsViewModel[] = this.cuesheets.map((cuesheet) => {
      return {
        ...cuesheet,
        ...this.productDetails.value,
        ...this.contactDetails.value,
      };
    });

    this.userContentService.saveCueSheet(cuesheets).then((cueSheetRes) => {
      let failed = cueSheetRes.find((res) => !res);
      if (!failed) {
        this.toast.success("Cue Sheet saved successfully.");
        if (!callback) {
          return this.router.navigateByUrl("/user-cuesheet");
        } else {
          callback(true);
        }
      } else {
        this.toast.error("Something went wrong. Please try again.");
      }
    });
  }

  addToCue() {
    const trackId = this.cueDetails.get("trackId").value;
    const index = this.cuesheets.findIndex(
      (cuesheet) => cuesheet.trackId == trackId
    );
    const cuesheet = {
      ...this.productDetails.value,
      ...this.contactDetails.value,
      ...this.cueDetails.value,
    };

    cuesheet.duration = `${this.cueDetails.value.durationHours}:${this.cueDetails.value.durationMinutes}:${this.cueDetails.value.durationSeconds}`;
    if (index >= 0) {
      const cuesheetCloned = [...this.cuesheets];
      const id = this.cuesheets[index].id;
      cuesheet.id = id;
      this.cuesheets[index] = cuesheet;
      this.cuesheets[index].trackId = cuesheetCloned[index].trackId;
    } else {
      // if (this.cuesheets[0]?.cueTitle) {
      //   this.userContentService
      //     .deleteCuesheet(this.cuesheets[0].id)
      //     .then((resp: any) => {
      //       if (!resp) {
      //         return this.toast.error(
      //           `cueesheet ${cuesheet.title} was not deleted, please contact admin`
      //         );
      //       }

      //       this.removeCuesheet(cuesheet);
      //       this.cuesheets.push(cuesheet);
      //     });
      // } else {
      this.cuesheets.push(cuesheet);
      //}
    }
  }

  edit(cuesheets: CueSheetsViewModel) {
    this.cueDetails.controls['trackId'].disable();
    const duration = cuesheets.duration;
    const splitted = duration.split(":");
    const durationHours = splitted[0];
    const durationMinutes = splitted[1];
    const durationSeconds = splitted[2];
    this.cueDetails.controls["durationHours"].setValue(durationHours);
    this.cueDetails.controls["durationMinutes"].setValue(durationMinutes);
    this.cueDetails.controls["durationSeconds"].setValue(durationSeconds);
    this.cueDetails.patchValue(cuesheets);
  }

  delete(cuesheet: any) {
    if (!cuesheet.id) return this.removeCuesheet(cuesheet);

    this.userContentService.deleteCuesheet(cuesheet.id).then((resp: any) => {
      if (!resp) {
        return this.toast.error(
          `cueesheet ${cuesheet.title} was not deleted, please contact admin`
        );
      }
      this.removeCuesheet(cuesheet);
    });
  }

  validateFormGroup(form: FormGroup) {
    form.markAllAsTouched();
  }

  submitAndDownload() {
    this.saveAndExit((resp) => {
      if (resp) {
        let contents = [];
        let pdf = new jsPDF("portrait", "px", "a4") as jsPDFWithPlugin;

        pdf.setFontSize(15);
        pdf.setTextColor(40);
        pdf.text("Cuesheet", 30, 15);

        this.addCuesheetTableContents(this.cuesheets, contents);

        pdf.autoTable({
          body: contents,
          columns: this.addCuesheetTableHeaders(),
        });

        pdf.save("cuesheet.pdf");
      }
    });
  }

  SelectedType(event: any) {
    const type = this.types.find((el) => el.name === event.target.value);
    if (type) {
      this.cueDetails.get("code").patchValue(type.code);
    }
  }

  private addCuesheetTableContents(cuesheet: any, contents: any[]) {
    for (let i = 0; i < cuesheet.length; i++) {
      const element = cuesheet[i];
      contents.push({
        title: element.cueTitle,
        publisher: element.authorName,
        artist: element.artist,
        company: element.recordingCompany,
        type: element.code,
        duration: element.duration,
        trackId: element.trackId,
        colSpan: 2,
        rowSpan: 2,
        styles: { halign: "center" },
      });
    }

    return contents;
  }

  private addCuesheetTableHeaders() {
    return [
      { header: "Title", dataKey: "title" },
      { header: "Publisher", dataKey: "publisher" },
      { header: "Artist", dataKey: "artist" },
      { header: "Company", dataKey: "company" },
      { header: "Type", dataKey: "type" },
      { header: "Duration", dataKey: "duration" },
      { header: "Track Id", dataKey: "trackId" },
    ];
  }

  private getCuesheets() {
    const cuesheets = this.userContentService.getStoredCueSheet();
    if (cuesheets && cuesheets.length > 0) {
      this.productDetails.patchValue(cuesheets[0]);
      this.contactDetails.patchValue(cuesheets[0]);
      this.cuesheets = cuesheets;
    }
  }

  private removeCuesheet(cuesheet: CueSheetsViewModel) {
    const index = this.cuesheets.indexOf(cuesheet);
    this.cuesheets.splice(index, 1);
  }

  private loadTypes() {
    this.types.push(
      {
        name: "Music Video",
        code: "VM",
      },
      {
        name: "Logo Music",
        code: "LM",
      },
      {
        name: "Studio Recording",
        code: "SR",
      },
      {
        name: "Mood (Library Music)",
        code: "MM",
      },
      {
        name: "Commissioned Music",
        code: "CM",
      },
      {
        name: "Buyout Music",
        code: "BUY",
      },
      {
        name: "Original Sound Recording",
        code: "OSR",
      }
    );
  }

  private createFormBuild() {
    this.createCuesheetForm = this.formBuilder.group({
      productDetails: this.formBuilder.group({
        userId: [""],
        cueSheetName: ["", [Validators.required]],
        title: ["", [Validators.required]],
        type: ["", [Validators.required]],
        runtime: ["", [Validators.required]],
        client: ["", [Validators.required]],
        company: ["", [Validators.required]],
      }),
      contactDetails: this.formBuilder.group({
        contact: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        address: ["", [Validators.required]],
        phoneNumber: ["", [Validators.required]],
      }),
      cueDetails: this.formBuilder.group({
        cueTitle: ["", [Validators.required]],
        authorName: ["", [Validators.required]],
        artist: ["", [Validators.required]],
        productionRuntime: ["", [Validators.required]],
        recordingCompany: ["", [Validators.required]],
        trackId: ["", [Validators.required]],
        code: ["", [Validators.required]],
        durationHours: ["", [Validators.required]],
        durationMinutes: ["", [Validators.required]],
        durationSeconds: ["", [Validators.required]],
      }),
    });
  }
}
