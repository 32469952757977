import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./services/security/auth.guard";
import { LogoutComponent } from "./sections/logout/logout.component";
import { ViewProfileComponent } from "./pages/view-profile/view-profile.component";
import { UserPlaylistComponent } from "./pages/user-playlist/user-playlist.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { UserCuesheetComponent } from "./pages/user-cuesheet/user-cuesheet.component";
import { UserOrdersComponent } from "./pages/user-orders/user-orders.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CuesheetsComponent } from "./pages/cuesheets/cuesheets.component";
import { CheckoutSucceededComponent } from "./pages/checkout/checkout-succeeded/checkout-succeeded.component";
import { NotifyTransactionComponent } from "./pages/checkout/notify-transaction/notify-transaction.component";
import { CheckoutFailedComponent } from "./pages/checkout/checkout-failed/checkout-failed.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { PlaylistTracksComponent } from "./pages/playlist-tracks/playlist-tracks.component";

const routes: Routes = [
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full",
	},
	{
		path: "home",
		data: {
			breadcrumb: "HOME",
		},
		loadChildren: "./modules/pages/pages.module#PagesModule",
	},
	{
		path: "about-us",
		data: {
			breadcrumb: "HOME",
		},
		loadChildren: "./modules/pages/pages.module#PagesModule",
	},
	{
		path: "logout",
		component: LogoutComponent,
	},
	{
		path: "view-profile",
		data: {
			breadcrumb: "PROFILE",
		},
		component: ViewProfileComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "user-playlist",
		data: {
			breadcrumb: "MY FAVOURITE TRACKS",
		},
		component: UserPlaylistComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "edit-profile",
		data: {
			breadcrumb: "EDIT USER PROFILE",
		},
		component: EditProfileComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "user-cuesheet",
		data: {
			breadcrumb: "USER CUESHEET",
		},
		component: UserCuesheetComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "user-orders",
		data: {
			breadcrumb: "USER PAST ORDERS",
		},
		component: UserOrdersComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "checkout",
		data: {
			breadcrumb: "CARTS",
		},
		component: CheckoutComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "success",
		data: {
			breadcrumb: "TRANSACTION SUCCEEDED",
		},
		component: CheckoutSucceededComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "transaction-details",
		component: NotifyTransactionComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "failed",
		data: {
			breadcrumb: "TRANSACTION FAILED",
		},
		component: CheckoutFailedComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "succeed",
		redirectTo: "checkout/succeed",
		pathMatch: "full",
		canActivate: [AuthGuard],
	},
	{
		path: "transaction-details",
		redirectTo: "checkout/transaction-details",
		pathMatch: "full",
		canActivate: [AuthGuard],
	},
	{
		path: "failed",
		redirectTo: "checkout/failed",
		pathMatch: "full",
		canActivate: [AuthGuard],
	},
	{
		path: "cue-sheet",
		data: {
			breadcrumb: "CREATE CUESHEET",
		},
		component: CuesheetsComponent,
		canActivate: [AuthGuard],
	},
	{ path: 'home/music-library/playlist/playlist-tracks/:playlistId', component: PlaylistTracksComponent },
	{ path: "**", component: PageNotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
