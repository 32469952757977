import { Component, OnInit } from "@angular/core";
import {
	Router,
	ActivatedRoute,
	RoutesRecognized,
	ActivatedRouteSnapshot,
} from "@angular/router";
import { filter } from "rxjs/operators";

interface RouteNode {
	name: string;
	fromRoot: string;
	path: string;
	children?: RouteNode[];
}

@Component({
	selector: "app-breadcrumb",
	templateUrl: "./breadcrumb.component.html",
	styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
	dataArray = new Array<RouteNode>();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.fillBreadcrumbRoutes(this.activatedRoute.snapshot.root);
		this.router.events
			.pipe(
				filter((event) => {
					// console.log({ event });
					return event instanceof RoutesRecognized;
				})
			)
			.subscribe((event: RoutesRecognized) => {
				this.dataArray = [];
				this.fillBreadcrumbRoutes(event.state.root);
				// console.log(this.dataArray);
			});
	}

	private fillBreadcrumbRoutes = (node: ActivatedRouteSnapshot) => {
		// @MM Requires the route to have a routeConfig attribute. (Indicates it's not an empty route)
		if (node.routeConfig && node.routeConfig.data) {
			// @MM Define node.
			// 		The multi tier map/reduce is a result of single data elements being stored in arrays.
			let routeNode: RouteNode = {
				name: node.routeConfig.data.breadcrumb,
				path: node.routeConfig.path,
				fromRoot: node.pathFromRoot
					.map((route) => {
						return route.url.length > 0
							? route.url
									.map((url) => {
										return url.path;
									})
									.reduce((prev, next) => {
										return prev + "/" + next;
									})
							: "";
					})
					.reduce((prev, next) => {
						return prev + "/" + next;
					}),
				children: [],
			};
			// @MM RouteConfig Children refer to available child routes for current node.
			// node.routeConfig.children
			// 	?.filter((childNode) => {
			// 		return childNode.data && childNode.data.breadcrumb;
			// 	})
			// 	.map((childNode) => {
			// 		let child: RouteNode = {
			// 			name: childNode.data.breadcrumb,
			// 			path: childNode.path,
			// 			fromRoot: routeNode.fromRoot + "/" + childNode.path,
			// 		};
			// 		routeNode.children.push(child);
			// 	});
			// @MM If we want to configure sibling routing/breadcrumbs

			// if (typeof node.routeConfig.loadChildren === "function") {
			// 	let loadChildren = node.routeConfig.loadChildren();
			// 	(loadChildren as Promise<any>).then((params) => {
			// console.log({ params });
			// 	});
			// }

			this.dataArray.push(routeNode);
		}
		// @MM Node Children are subsequent route nodes in current UrlTree.
		node.children?.map((next) => this.fillBreadcrumbRoutes(next));
	};
}
