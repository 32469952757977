import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { SearchUseCase } from "src/app/useCases/search.usecase";
import { SongsService } from "src/app/services/songs/songs.service";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "app-search-container",
	templateUrl: "./search-container.component.html",
	styleUrls: ["./search-container.component.scss"],
})
export class SearchContainerComponent implements OnInit {
	filterForm: FormGroup = new FormGroup({});

	composers: any[] = [];
	genres: any[] = [];
	instruments: any[] = [];
	moods: any[] = [];
	tempos: any[] = [];

	filters: any[] = [];

	prices$: Observable<PriceViewModel[]>;
	playlist: any[];
	isActive: true;
	isActivePage: true;
	itemsToDisplay = 10;

	constructor(
		private searchUseCase: SearchUseCase,
		private songsService: SongsService,
		private formBuilder: FormBuilder,
		private spinner: NgxSpinnerService
	) {}

	ngOnInit(): void {
		this.loadFilters();
	}

	private async loadFilters() {
		if (this.songsService.filterSelected)
			this.filters = this.songsService.filters$.value;

		this.searchUseCase.getComposers().subscribe((composers) => {
			composers.map((composer) => {
				this.filterForm.addControl(
					"composer-" + composer["key"],
					new FormControl(this.filters.includes(composer.name))
				);
			});
			this.composers = [...composers];
		});
		this.searchUseCase.getGenres().subscribe((genres) => {
			genres.map((genre) => {
				this.filterForm.addControl(
					"genre-" + genre["key"],
					new FormControl(this.filters.includes(genre.name))
				);
			});
			this.genres = [...genres];
		});
		this.searchUseCase.getInstruments().subscribe((instruments) => {
			instruments.map((instrument) => {
				this.filterForm.addControl(
					"instrument-" + instrument["key"],
					new FormControl(this.filters.includes(instrument.name))
				);
			});
			this.instruments = [...instruments];
		});
		this.searchUseCase.getMoods().subscribe((moods) => {
			moods.map((mood) => {
				this.filterForm.addControl(
					"mood-" + mood["key"],
					new FormControl(this.filters.includes(mood.name))
				);
			});
			this.moods = [...moods];
		});
		this.searchUseCase.getTempos().subscribe((tempos) => {
			tempos.map((tempo) => {
				this.filterForm.addControl(
					"tempo-" + tempo["key"],
					new FormControl(this.filters.includes(tempo.name))
				);
			});
			this.tempos = [...tempos];
		});
	}

	public applyFilters() {
		let grouped = {
			composer: this.composers,
			genre: this.genres,
			instrument: this.instruments,
			mood: this.moods,
			tempo: this.tempos,
		};
		Object.keys(this.filterForm.controls).map((control) => {
			if (this.filterForm.controls[control].value == true) {
				let category = control.split("-")[0];
				let key = control.split("-")[1];
				let name = grouped[category].find((item) => item.key == key)
					.name;
				// console.log({
				// 	control,
				// 	category,
				// 	name,
				// });
				this.songsService.addFilter(category, name);
			}
		});
	}
}
