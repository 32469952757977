import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import WaveSurfer from "wavesurfer.js";

import { AuthService } from "src/app/services/security/auth-service";
import { SongsService } from "src/app/services/songs/songs.service";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { ModalService } from "src/app/utils/modal";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";

@Component({
  selector: "urf-player",
  templateUrl: "./player.component.html",
  styleUrls: ["./player.component.scss"],
})
export class PlayerComponent implements OnInit, AfterViewInit {
  @ViewChild("waveplayer") waveplayer: ElementRef;

  private song: RecordViewModel = null;
  public currentlyPlaying$: Observable<RecordViewModel> =
    this.playTrackUseCase.currentlyPlaying;

  public songReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public wavesurfer;
  public trackEndtime: Date = new Date(0);
  public currentTime: Date = new Date(0);
  public currentTime$: BehaviorSubject<number> = new BehaviorSubject(
    this.currentTime.getTime()
  );

  public isPlaying: boolean = false;
  public waveformLoading: boolean = true;

  public mobileShowWaveform = false;
  public showDialog = false;
  public isFavourite = false;

  constructor(
    private playTrackUseCase: PlayTrackUseCase,
    private changeRef: ChangeDetectorRef,
    private userContentService: UserContentService,
    private authServ: AuthService,
    private toast: ToastrService,
    private modalService: ModalService,
    private ngxSpinnerService: NgxSpinnerService,
    private songService: SongsService
  ) {}

  ngOnInit(): void {
    this.userContentService.getUserPlaylist().subscribe(() => {
      this.isFavourite = this.userContentService.songIsFavourite(this.song);
    });
  }

  ngAfterViewInit() {
    this.currentTime$.subscribe(() => this.changeRef.detectChanges());
    this.currentlyPlaying$.subscribe((song) => {
      this.song = song;
      this.ngxSpinnerService.show("waveformLoading");
      this.waveformLoading = true;
      if (this.wavesurfer) {
        this.wavesurfer.destroy();
        this.isPlaying = false;
        this.updateTime(0);
      }
      requestAnimationFrame(() => {
        if (this.song) {
          this.initialiseWavesurfer(this.song);
        } else {
          if (this.wavesurfer) this.wavesurfer.empty();
        }
      });
      this.isFavourite = this.userContentService.songIsFavourite(this.song);
    });
  }
  public close() {
    this.playTrackUseCase.playSong(null);
  }
  public addFilter(category: string, filter: string) {
    if (category && filter) this.songService.addFilter(category, filter);
  }
  public openMobileView() {
    this.mobileShowWaveform = !this.mobileShowWaveform;
  }
  private updateTime(value: number) {
    this.currentTime.setMinutes(0, Math.floor(value));
    if (this.currentTime.getTime() != this.currentTime$.getValue())
      this.currentTime$.next(this.currentTime.getTime());
  }
  public playPause() {
    if (this.waveformLoading) return;
    this.wavesurfer.playPause();
    this.isPlaying = this.wavesurfer.isPlaying();
  }
  public initialiseWavesurfer(song: RecordViewModel) {
    this.wavesurfer = WaveSurfer.create({
      container: this.waveplayer.nativeElement,
      waveColor: "#ddd",
      progressColor: "#C1AE69",
      barWidth: 4,
      partialRender: true,
    });
    this.wavesurfer.on("ready", () => {
      this.trackEndtime.setSeconds(this.wavesurfer.getDuration());
      this.trackEndtime = new Date(this.trackEndtime);
      this.ngxSpinnerService.hide("waveformLoading");
      this.waveformLoading = false;
      this.changeRef.detectChanges();
    });
    this.wavesurfer.on("waveform-ready", () => {
      this.trackEndtime.setSeconds(this.wavesurfer.getDuration());
      this.trackEndtime = new Date(this.trackEndtime);
      this.ngxSpinnerService.hide("waveformLoading");
      this.waveformLoading = false;
    });
    this.wavesurfer.on("audioprocess", (value) => {
      this.updateTime(value);
    });
    this.wavesurfer.on("seek", () => {
      // if (!this.wavesurfer.isPlaying())
      this.updateTime(this.wavesurfer.getCurrentTime());
    });

    this.playTrackUseCase.loadSongUrl(song).then(
      (url) => {
        this.wavesurfer.load(url);
      },
      (err) => {
        this.toast.error("Track file error.");
        this.modalService.close("player");
      }
    );
  }
  addToPlaylist(song: any) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " added to your favourites."
          );
        } else {
          this.toast.error(
            "Item could not be added to your favourites, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }
  removeFromPlaylist(song: any) {
    if (this.authServ.loggedIn()) {
      this.userContentService.removeSongFromPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " removed from your favourites."
          );
        } else {
          this.toast.error(
            "Item could not be removed from your favourites, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }

  private openModal() {
    this.showDialog = true;
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
  }

  addToCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToCart(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(title.toUpperCase() + " added to your cart.");
        } else {
          this.toast.error(
            "Item could not be added to your cart, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }
}
