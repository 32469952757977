import { Component, OnInit } from "@angular/core";
import { CmsService } from "src/app/services/cms/cms.service";
import { TCViewModel } from "src/app/viewModels/tc.viewmodel";

@Component({
	selector: "app-tc",
	templateUrl: "./tc.component.html",
	styleUrls: ["./tc.component.scss"],
})
export class TcComponent implements OnInit {
	tc: TCViewModel;
	showTc = false;

	constructor(private getTCUsUsecase: CmsService) {}

	ngOnInit() {
		this.loadTc();
	}

	private loadTc() {
		this.getTCUsUsecase.getTC().subscribe((resp) => {
			if (resp) {
				this.showTc = true;
				this.tc = resp;
			}
		});
	}
}
