import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { PayFastUseCase } from "src/app/useCases/payfast.usecase";
import { Router } from "@angular/router";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { CdkStep } from "@angular/cdk/stepper";
import { BehaviorSubject } from "rxjs";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { TrackTableConfig } from "src/app/components/track-table/track-table.component";
import { UrfTrackComponent } from "src/app/components/urf-track/urf-track.component";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-checkout-succeeded",
  templateUrl: "./checkout-succeeded.component.html",
  styleUrls: ["./checkout-succeeded.component.scss"],
})
export class CheckoutSucceededComponent implements OnInit {
  @ViewChild("paymentStep") paymentStep: CdkStep;

  public songs$: BehaviorSubject<RecordViewModel[]> = new BehaviorSubject([]);
  public payment$: BehaviorSubject<any> = new BehaviorSubject(null);
  public finalConfig: TrackTableConfig = {
    tracksTableMetaData: false,
    paginationControls: false,
    licenceSelect: false,
    trackControls: "download",
  };

  public downloading = false;

  constructor(
    private payFastUseCase: PayFastUseCase,
    private userContentService: UserContentService,
    private playTrackUseCase: PlayTrackUseCase,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router
  ) {
    // console.log("construction finished");
    this.payment$.subscribe((payment) => {
      if (payment && payment.songs) this.songs$.next(payment.songs);
    });
  }

  ngOnInit() {
    this.verifyState();
  }

  public downloadAll() {
    if (this.downloading) return;
    this.downloading = true;
    const songs: RecordViewModel[] = this.songs$.getValue();
    if (songs && songs.length > 0) {
      this.ngxSpinnerService.show("downloadAll");
      Promise.all(
        songs.map((song) => this.playTrackUseCase.downloadSong(song))
      ).then(() => {
        this.downloading = false;
        this.ngxSpinnerService.hide("downloadAll");
      });
    } else {
      this.downloading = false;
      this.ngxSpinnerService.hide("downloadAll");
    }
  }
  public verifyState(): void {
    this.payFastUseCase.updateSuccessfulUserPayment().then((res) => {
      // console.log(res);
      if (res.data) {
        this.payment$.next(res.data);
      }
      this.userContentService.checkIfPaymentWasMade().then((paid) => {
        if (paid) {
          this.paymentStep.completed = true;
        } else {
          this.paymentStep.hasError = true;
          this.paymentStep.errorMessage =
            "Something went wrong loading your purchase.";
        }
      });
    });
  }

  navigateTo() {
    this.router.navigateByUrl("/home");
  }
}
