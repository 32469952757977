
export class RecordViewModel {
  key: string;
  trackId: string;
  fileName: string;
  trackName: string;
  artistName: string;
  author: string;
  label: string;
  publisher: string;
  yearComposed: string;
  trackDuration: string;
  country: string;
  instrument: string;
  genre: string;
  tempo: string;
  mood: string;
  format: string;
  production: string;
  language: string;
  vocalType: string;
  explicit: string;
  keywords: string;
  active: false;
  //timestamp: firestore.FieldValue.;
  url: string;
  songId: string;
  description: string;
  thumbnail: string;
  songTitle: string;
  composer: string;
}
