import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EnquiryUsecase } from "src/app/useCases/enquiry.usecase";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "src/app/utils/modal";
import { ToastrService } from "ngx-toastr";
import { touchAllFormFields } from "src/app/utils/validation";

@Component({
	selector: "app-enquiry",
	templateUrl: "./enquiry.component.html",
	styleUrls: ["./enquiry.component.scss"],
})
export class EnquiryComponent implements OnInit, AfterViewInit {
	public enquiryType: "contact" | "studio" = "contact";
	enquiryForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private enquiryUsecase: EnquiryUsecase,
		private router: Router,
		private modalService: ModalService,
		private toast: ToastrService,
		private activatedRoute: ActivatedRoute
	) {
		this.createFormBuild();
	}

	ngOnInit() {}
	ngAfterViewInit() {
		const breadcrumb: string = this.activatedRoute.snapshot.data[
			"breadcrumb"
		];

		switch (breadcrumb) {
			case "CONTACT":
				this.enquiryType = "contact";
				break;
			default:
				this.enquiryType = "studio";
				break;
		}
	}

	send() {
		if (!this.enquiryForm.valid) {
			touchAllFormFields(this.enquiryForm);
			return;
		}
		const d = new Date();
		this.enquiryForm.controls.enquiryDate.setValue(d);
		this.enquiryUsecase
			.enquire(this.enquiryForm.value)
			.then((resp) => {
				if (resp.data) {
					this.toast.success("Message sent successfully");
					this.enquiryForm.reset();
					this.closeModal();
				} else {
					this.toast.error("Error, Message not sent");
				}
			});
	}

	private closeModal() {
		if (this.router.url === "/home/about-us/studio") {
			this.close("enquiry");
			return;
		}
	}

	private close(id: string) {
		this.modalService.close(id);
	}

	private createFormBuild() {
		this.enquiryForm = this.formBuilder.group({
			name: ["", [Validators.required]],
			number: ["", [Validators.required]],
			email: ["", [Validators.required, Validators.email]],
			enquiry: ["", [Validators.required]],
			message: ["", [Validators.required]],
			enquiryDate: [""],
		});
	}
}
