import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SearchUseCase } from "src/app/useCases/search.usecase";
import { SongsService } from "src/app/services/songs/songs.service";
import { touchAllFormFields } from "src/app/utils/validation";

@Component({
	selector: "app-composers",
	templateUrl: "./composers.component.html",
	styleUrls: ["./composers.component.scss"],
})
export class ComposersComponent implements OnInit {
	searchForm: FormGroup;

	constructor(
		private searchUseCase: SearchUseCase,
		private formBuilder: FormBuilder,
		private songsService: SongsService
	) {
		this.createFormBuild();
	}

	ngOnInit() {
		this.searchForm.setValue({
			searchStr: this.songsService.currentSearch,
		});
	}

	public search() {
		if (!this.searchForm.valid) {
			touchAllFormFields(this.searchForm);
			return;
		}
		this.songsService.search(this.searchForm.get("searchStr").value);
	}

	private createFormBuild() {
		this.searchForm = this.formBuilder.group({
			searchStr: ["", Validators.required],
			// composer: [''],
			// genre: [''],
			// instrument: [''],
			// mood: [''],
			// tempo: [''],
			// price: [''],
		});
	}
}
