import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Observable } from "rxjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";

import { CueSheetsViewModel } from "src/app/viewModels/cuesheets.viewmodel";
import { UserContentService } from "src/app/services/user/content/user-content.service";

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: "app-user-cuesheet",
  templateUrl: "./user-cuesheet.component.html",
  styleUrls: ["./user-cuesheet.component.scss"],
})
export class UserCuesheetComponent {
  public cuesheets$: Observable<Map<string, CueSheetsViewModel[]>>;

  pages = 1;

  faDownload = faDownload;
  faPen = faPen;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  constructor(
    private router: Router,
    private userContentService: UserContentService
  ) {
    this.cuesheets$ = this.userContentService.getUserCueSheets();
  }

  create() {
    this.userContentService.storeCueSheetType("create");
    this.userContentService.storeCueSheet(undefined);
    this.router.navigateByUrl("/cue-sheet");
  }

  download(cuesheets: any) {
    let contents = [];
    let pdf = new jsPDF("portrait", "px", "a4") as jsPDFWithPlugin;

    pdf.setFontSize(15);
    pdf.setTextColor(40);
    pdf.text("Cuesheet", 30, 15);

    this.addCuesheetTableContents(cuesheets, contents);

    pdf.autoTable({
      body: contents,
      columns: this.addCuesheetTableHeaders(),
    });

    pdf.save("cuesheet.pdf");
  }

  private addCuesheetTableContents(cuesheet: any, contents: any[]) {
    for (let i = 0; i < cuesheet.length; i++) {
      const element = cuesheet[i];
      contents.push({
        title: element.cueTitle,
        publisher: element.authorName,
        artist: element.artist,
        company: element.recordingCompany,
        type: element.code,
        duration: element.duration,
        trackId: element.trackId,
        colSpan: 2,
        rowSpan: 2,
        styles: { halign: "center" },
      });
    }

    return contents;
  }

  private addCuesheetTableHeaders() {
    return [
      { header: "Title", dataKey: "title" },
      { header: "Publisher", dataKey: "publisher" },
      { header: "Artist", dataKey: "artist" },
      { header: "Company", dataKey: "company" },
      { header: "Type", dataKey: "type" },
      { header: "Duration", dataKey: "duration" },
      { header: "Track Id", dataKey: "trackId" },
    ];
  }

  edit(cuesheets: CueSheetsViewModel[]) {
    this.userContentService.storeCueSheet(cuesheets);
    this.router.navigateByUrl("/cue-sheet");
  }
}
