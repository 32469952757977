// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: "https://urbanrhythmfactory.co.za/api", //"http://client-env.eba-9umevppy.af-south-1.elasticbeanstalk.com/api",
  merchant_id: "14939063",
  merchant_key: "lf4wrn73kqvr7",
  source_audio_token: '8955-4e3afc1d2bd4c6dfeb567b6ac3d202f5',
  source_audio_domain: 'https://urbanrhythmfactory.sourceaudio.com/api',
  apiUrl: "http://localhost:4000",//"http://localhost:4000/api", 
  //merchant_id: "14939063", //"10016508",
  // merchant_key: 'pm2v90n65u1xd',
  //merchant_id: "14939063",
  //merchant_key: "lf4wrn73kqvr7", //"lf4wrn73kqvr7",
  // merchant_id: "10019871",
  // merchant_key: "aqg70njviyrbw",
  firebase: {
    apiKey: "AIzaSyAwO2M3EX2Dg88TEotCoH3SVNRlyz9w_s0",
    authDomain: "urf-music.firebaseapp.com",
    databaseURL: "https://urf-music.firebaseio.com",
    projectId: "urf-music",
    storageBucket: "urf-music.appspot.com",
    messagingSenderId: "346845784340",
    appId: "1:346845784340:web:1f19fd50fdc92eca",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
