import { Component, OnInit } from "@angular/core";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { AboutUsViewModel } from "src/app/viewModels/about-us.viewmodel";
import { Router } from "@angular/router";
import { toInteger } from "lodash";

import { CmsService } from "src/app/services/cms/cms.service";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { ModalService } from "src/app/utils/modal";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  playlist: any[];
  itemsToDisplay = 10;

  aboutUs: AboutUsViewModel;
  showAboutUs = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private getAboutUs: CmsService,
    private userContentService: UserContentService,
  ) {}

  ngOnInit() {
    this.loadAboutUs();
  }

  public login = () => {
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  };

  public signUp = () => {
    const id = "sign-up";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  };

  showTracks(playlist) {
    this.userContentService.addToPlaylist(playlist);
    this.router.navigateByUrl("/playlist-tracks");
  }

  navigateTo(path: string) {
    const homeUrl = "/home";
    if (path) {
      const url = `${homeUrl}/${path}`;
      return this.router.navigateByUrl(url);
    }
    this.router.navigateByUrl("/home");
  }

  public isLoggedIn = () => {
    if (localStorage.getItem("user")) {
      return true;
    }
    return false;
  };

  private loadAboutUs() {
    this.getAboutUs.getAboutUs().subscribe((resp) => {
      if (resp != null) {
        this.showAboutUs = true;
        this.aboutUs = resp;
      }
    });
  }

  public getTrackArray(price: PriceViewModel) {
    let max = toInteger(price.numberOfTrack);
    let list = [...Array(max).keys()].reverse().map((value) => value + 1);
    return list;
  }
}
