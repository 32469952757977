const MapStyle: google.maps.MapTypeStyle[] = [
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: 36,
			},
			{
				color: "#000000",
			},
			{
				lightness: 40 + 13,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#000000",
			},
			{
				lightness: 16 + 13,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 20 + 13,
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 17 + 13,
			},
			{
				weight: 1.2,
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 20 + 13,
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 21 + 13,
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 17 + 13,
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 29 + 13,
			},
			{
				weight: 0.2,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 18 + 13,
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 16 + 13,
			},
		],
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 19 + 13,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#000000",
			},
			{
				lightness: 17 + 13,
			},
		],
	},
];

export default MapStyle;
