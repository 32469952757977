import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";

import { environment } from "../../environments/environment";

const AUTH_TOKEN = environment.source_audio_token;
const DOMAIN = environment.source_audio_domain;

@Injectable({
  providedIn: "root",
})
export class TrackGateway {
  constructor(private http: HttpClient,
    private firestore: AngularFirestore) {
  }

  getPlaylist(): Observable<any> {
    const url = `${DOMAIN}/playlists/getPublished?token=${AUTH_TOKEN}`;
    return this.http.get(url);
  }

  getPopularTracks(): Observable<any> {
    return this.firestore.collection('UserPlaylist').valueChanges();
  }

  playSong(id: any): Observable<any> {
    const url = `${DOMAIN}/tracks/downloadAuth?track_id=${id}&token=${AUTH_TOKEN}`;
    return this.http.get(url);
  }
}
