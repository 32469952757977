import { Component, OnInit } from "@angular/core";

import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Router } from "@angular/router";
import { ContactUsViewModel } from "src/app/viewModels/contact-us.viewmodel";
import { FooterViewModel } from "src/app/viewModels/home-footer.viewmodel";
import { ModalService } from "src/app/utils/modal";
import { CmsService } from "src/app/services/cms/cms.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	faFacebook = faFacebook;
	faTwitter = faTwitter;
	faInstagram = faInstagram;

	contactUs: ContactUsViewModel;
	showContactUs = false;

	footer = new FooterViewModel();
	showFooter = false;

	constructor(
		private modalService: ModalService,
		private cmsService: CmsService
	) {}

	ngOnInit() {
		this.loadFooterSocial();
		this.loadContactUs();
	}

	private loadFooterSocial() {
		this.cmsService.getFooter().subscribe((resp) => {
			if (resp != null) {
				// console.log("FOOTER RESPONSE", resp);
				this.removeHTMLTag(resp);
				this.showFooter = true;
			}
		});
	}

	private removeHTMLTag(data: any): any {
		if (data.facebook) {
			this.footer.facebook = data.facebook.replace(/(<([^>]+)>)/gi, "");
		}
		if (data.instagram) {
			this.footer.instagram = data.instagram.replace(/(<([^>]+)>)/gi, "");
		}
	}

	private loadContactUs() {
		this.cmsService.getContactUs().subscribe((resp) => {
			if (resp) {
				this.showContactUs = true;
				this.contactUs = resp;
			}
		});
	}
	public isLoggedIn = () => {
		// @MM Return logged in status.
		// TODO: Change to active login check.
		return !!localStorage.getItem("user");
	};

	public login = () => {
		const id = "login";
		this.modalService.add(id);
		this.modalService.open(id);
		return true;
	};

	public signUp = () => {
		const id = "sign-up";
		this.modalService.add(id);
		this.modalService.open(id);
		return true;
	};
}
