import { Component, OnInit } from "@angular/core";
import { faHeart, faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { SafeUrl } from "@angular/platform-browser";
import { first } from "rxjs/operators";

import { GetPopularTracksUseCase } from "src/app/useCases/get-popular-tracks.usecase";
import { AuthService } from "src/app/services/security/auth-service";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { ModalService } from "src/app/utils/modal";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { UserContentService } from "src/app/services/user/content/user-content.service";

@Component({
  selector: "app-popular-tracks",
  templateUrl: "./popular-tracks.component.html",
  styleUrls: ["./popular-tracks.component.css"],
})
export class PopularTracksComponent implements OnInit {
  prices: any;
  popularTracks: any[];
  loggedUser: any = {};

  faHeart = faHeart;
  faCartArrowDown = faCartArrowDown;
  isSelected = false;

  p = 1;
  types = [];
  playlist: SafeUrl;
  song: any;

  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  msbapDisplayTitle = true;
  msaapPageSizeOptions = [2, 4, 6];
  msaapDisplayVolumeControls = true;
  msbapDisplayVolumeControls = true;

  showDialog = false;

  constructor(
    private getPopularTracksUseCase: GetPopularTracksUseCase,
    private priceUseCase: PriceUseCase,
    private userContentService: UserContentService,
    private modalService: ModalService,
    private authServ: AuthService
  ) {
    this.priceUseCase
      .getPrices()
      .pipe(first())
      .toPromise()
      .then((result) => {
        this.prices = result;
      });
  }

  ngOnInit() {
    this.loadPopularTracks();
  }

  closeFilter(p: any) {
    return;
  }

  addToPlaylist(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToPlaylist(song).then(() => {});
    } else {
      this.openModal();
    }
  }

  addToCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToCart(song).then(() => {});
    } else {
      const id = "login";
      this.modalService.add(id);
      this.openModal();
    }
  }

  private openModal() {
    this.showDialog = true;
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
  }

  private loadPopularTracks() {
    this.getPopularTracksUseCase.get().subscribe((resp: any) => {
      this.popularTracks = resp.data;
    });
  }
}
