import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ComposerViewModel } from "src/app/viewModels/composer.viewmodel";
import { GenreViewModel } from "src/app/viewModels/genre.viewmodel";
import { InstrumentViewModel } from "src/app/viewModels/instrument.viewmodel";
import { MoodViewModel } from "src/app/viewModels/mood.viewmodel";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SearchUseCase } from "src/app/useCases/search.usecase";
import { SongsService } from "src/app/services/songs/songs.service";
import { touchAllFormFields } from "src/app/utils/validation";
import { Router } from "@angular/router";

@Component({
	selector: "app-search",
	templateUrl: "./search.component.html",
	styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
	composers$: Observable<ComposerViewModel[]>;
	genres$: Observable<GenreViewModel[]>;
	instruments$: Observable<InstrumentViewModel[]>;
	moods$: Observable<MoodViewModel[]>;
	tempos$: Observable<MoodViewModel[]>;

	searchForm: FormGroup;

	showFilterControls: boolean = false;

	constructor(
		private searchUseCase: SearchUseCase,
		private formBuilder: FormBuilder,
		private songsService: SongsService,
		private router: Router
	) {
		this.createFormBuild();
	}

	ngOnInit(): void {
		this.loadFilters();
		this.searchForm.setValue({
			searchStr: this.songsService.currentSearch,
		});
	}

	private loadFilters() {
		this.composers$ = this.searchUseCase.getComposers();
		this.genres$ = this.searchUseCase.getGenres();
		this.instruments$ = this.searchUseCase.getInstruments();
		this.moods$ = this.searchUseCase.getMoods();
		this.tempos$ = this.searchUseCase.getTempos();
	}
	public search() {
		if (!this.searchForm.valid) {
			touchAllFormFields(this.searchForm);
			return;
		}
		this.songsService.search(this.searchForm.get("searchStr").value);

		if (!this.router.isActive("/home/search", true)) {
			this.router.navigateByUrl("/home/search");
		}
	}
	public selectFilter(category: string, filter: string) {
		this.songsService.addFilter(category, filter.toLowerCase());
	}
	private createFormBuild() {
		this.searchForm = this.formBuilder.group({
			searchStr: ["", Validators.required],
		});
	}

	private getSelectedDropDownValue(property) {
		if (property === "composer") {
			return this.searchForm.controls.composer.value;
		}
		if (property === "genre") {
			return this.searchForm.controls.genre.value;
		}
		if (property === "instrument") {
			return this.searchForm.controls.instrument.value;
		}
		if (property === "mood") {
			return this.searchForm.controls.mood.value;
		}
		if (property === "tempo") {
			return this.searchForm.controls.tempo.value;
		}
	}
}
