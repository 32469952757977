import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Observable, BehaviorSubject } from "rxjs";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import {
  faHeart,
  faCartArrowDown,
  faMinusCircle,
  faTimes,
  faPlay,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faCross,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { PriceUseCase } from "src/app/useCases/price.usecase";
import { AuthService } from "src/app/services/security/auth-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "src/app/utils/modal";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { SongsService } from "src/app/services/songs/songs.service";

import { ToastrService } from "ngx-toastr";

export interface TrackTableConfig {
  tracksTableMetaData?: boolean;
  paginationControls?: boolean;
  licenceSelect?: boolean;
  trackControls?: "view" | "list" | "download" | "extend";
}

@Component({
  selector: "urf-track-table",
  templateUrl: "./track-table.component.html",
  styleUrls: ["./track-table.component.scss"],
})
export class TrackTableComponent implements OnInit {
  @Input("config") config: TrackTableConfig = {
    tracksTableMetaData: true,
    paginationControls: true,
    licenceSelect: true,
    trackControls: "view",
  };
  @Input("songs") public set inputSongs(value: Observable<RecordViewModel[]>) {
    this.songs$ = value;
  }

  imageUrl = "";
  song: any;

  // @MM Filter Keep
  filteredSongs: any[];

  // @MM Filter Models
  songs$: Observable<RecordViewModel[]>;
  filters$: Observable<string[]>;
  sorts$: Observable<{ [key: string]: boolean }>;

  // @MM Icon Keep
  faHeart = faHeart;
  faCartArrowDown = faCartArrowDown;
  faMinusCircle = faMinusCircle;
  faTimes = faTimes;
  faPlay = faPlay;
  faPlus = faPlus;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faDownload = faDownload;

  filterSelected = false;
  pages = 1;
  size = 0;

  composer: string;
  filters = {};
  types = [];
  arrDiff = [];

  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  msbapDisplayTitle = true;
  msaapPageSizeOptions = [2, 4, 6];
  msaapDisplayVolumeControls = true;
  msbapDisplayVolumeControls = true;

  constructor(
    private spinner: NgxSpinnerService,
    private playTrackUseCase: PlayTrackUseCase,
    private sanitizer: DomSanitizer,
    private songsService: SongsService,
    private changeRef: ChangeDetectorRef
  ) {
    this.filters$ = this.songsService.filters$.asObservable();
    this.sorts$ = this.songsService.sorts$.asObservable();
  }

  ngOnInit(): void {
    if (!this.songs$) this.songs$ = this.songsService.songs$.asObservable();
    this.filters$.subscribe((data) => (this.filterSelected = data.length > 0));
  }

  // load(song: RecordViewModel) {
  // 	this.currentlyPlaying = song.songId;
  // 	// this.changeRef.detectChanges();
  // 	if (this.wavesurfer) this.wavesurfer.destroy();

  // 	this.initialiseWavesurfer(song);
  // }

  public closeFilter(property: string) {
    // this.removeSelectedButton(property);
    this.songsService.clearFilter(property);
  }

  public enableSort(sort: string) {
    this.songsService.sort(sort);
  }
}
