import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "src/app/utils/modal";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

import { PriceUseCase } from "src/app/useCases/price.usecase";
import { SongsService } from "src/app/services/songs/songs.service";
import { PriceViewModel } from "src/app/viewModels/price.viewmodel";
import { AuthService } from "src/app/services/security/auth-service";
import { RecordViewModel } from "src/app/viewModels/record.viewmodel";
import { PlayTrackUseCase } from "src/app/useCases/play-track.usecase";
import { TrackTableConfig } from "../track-table/track-table.component";
import { UserContentService } from "src/app/services/user/content/user-content.service";

@Component({
  selector: "urf-track",
  templateUrl: "./urf-track.component.html",
  styleUrls: ["./urf-track.component.scss"],
})
export class UrfTrackComponent implements OnInit {
  @Input("song") public song: RecordViewModel;
  @Input("config") public config: TrackTableConfig;

  public showDownloadSpinner = false;
  public showDialog = false;
  public isFavourite = false;
  public prices$: BehaviorSubject<Map<string, PriceViewModel[]>> =
    new BehaviorSubject(null);

  constructor(
    private playTrackUseCase: PlayTrackUseCase,
    private authServ: AuthService,
    private userContentService: UserContentService,
    private toast: ToastrService,
    private modalService: ModalService,
    private priceUseCase: PriceUseCase,
    private songService: SongsService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.priceUseCase.getPrices().subscribe((prices) => {
      if (!this.prices$.getValue() || this.prices$.getValue()?.size == 0)
        this.prices$.next(
          this.priceUseCase.getGroupedPrices((price) => price.name)
        );
    });
  }

  async ngOnInit(): Promise<void> {
    this.userContentService.getUserPlaylist().subscribe((resp) => {
      this.isFavourite = this.userContentService.songIsFavourite(this.song);
    });
  }

  public selectPrice(song: RecordViewModel, price: PriceViewModel) {
    this.userContentService.selectCartItemLicence(song, price);
  }

  public playSong(song: any) {
    this.playTrackUseCase.playSong(song);
  }

  public downloadSong(song: RecordViewModel) {
    this.showDownloadSpinner = true;
    this.ngxSpinnerService.show("download");
    this.playTrackUseCase.downloadSong(song).then((success) => {
      this.ngxSpinnerService.hide("download");
      this.showDownloadSpinner = false;
      if (!success) this.toast.error("Something went wrong. Please try again.");
    });
  }

  addToPlaylist(song: any) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " added to your favourites."
          );
        } else {
          this.toast.error(
            "Item could not be added to your favourites, probably it already existing, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }

  removeFromPlaylist(song: any) {
    if (this.authServ.loggedIn()) {
      this.userContentService.removeSongFromPlaylist(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(
            title.toUpperCase() + " removed from your favourites."
          );
          this.isFavourite = false;
        } else {
          this.toast.error(
            "Item could not be removed from your favourites, please contact the administrator"
          );
        }
      });
    } else {
      this.openModal();
    }
  }

  addToCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.addToCart(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(title.toUpperCase() + " added to your cart.");
        } else {
          this.toast.error(
            "Item could not be added to your cart, probably it already existing, if not please contact the administrator"
          );
        }
      });
    } else {
      const id = "login";
      this.modalService.add(id);
      this.openModal();
    }
  }

  removeFromCart(song: RecordViewModel) {
    if (this.authServ.loggedIn()) {
      this.userContentService.removeFromCart(song).then((success) => {
        if (success) {
          let title = song.songTitle ? song.songTitle : song.fileName;
          this.toast.success(title.toUpperCase() + " removed from your cart.");
        } else {
          this.toast.error(
            "Item could not be removed from your cart, please contact the administrator"
          );
        }
      });
    }
  }

  public addFilter(composer: string) {
    if (composer) this.songService.addFilter("composer", composer);
  }

  private openModal() {
    this.showDialog = true;
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
  }
}
