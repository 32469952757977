import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { PriceGateway } from "../gateways/price.gateways";
import { PriceViewModel } from "../viewModels/price.viewmodel";
import { RecordViewModel } from "../viewModels/record.viewmodel";

const TEST_ALL_MEDIA = [
	{
		blanket: true,
		includeCutDown: true,
		key: "lD8QGaQTByC2V4qW4fae",
		name: "All Media Usage",
		nonExclusive: true,
		numberOfTrack: "100",
		price: "95000",
		syncRight: true,
		usage: "5",
	},
	{
		blanket: true,
		includeCutDown: true,
		key: "lD8QGaQTByC2V4qW4fae",
		name: "All Media Usage",
		nonExclusive: true,
		numberOfTrack: "75",
		price: "75000",
		syncRight: true,
		usage: "5",
	},
	{
		blanket: true,
		includeCutDown: true,
		key: "lD8QGaQTByC2V4qW4fae",
		name: "All Media Usage",
		nonExclusive: true,
		numberOfTrack: "50",
		price: "55000",
		syncRight: true,
		usage: "5",
	},
];
@Injectable({
	providedIn: "root",
})
export class PriceUseCase {
	public prices$: BehaviorSubject<PriceViewModel[]> = new BehaviorSubject([]);

	constructor(private priceGateway: PriceGateway) {}

	getPrices(): Observable<PriceViewModel[]> {
		this.priceGateway.getPrices().subscribe((prices: any[]) => {
			// console.log({ prices });
			if (prices.length > 0)
				this.prices$.next(prices.concat(TEST_ALL_MEDIA));
		});
		return this.prices$;
	}

	getGroupedPrices(keyGetter): Map<string, PriceViewModel[]> {
		return this.groupBy(this.prices$.getValue(), keyGetter);
	}

	private groupBy = (prices, keyGetter) => {
		const map = new Map<string, any[]>();
		prices.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		// console.log({ map });
		return map;
	};
}
