import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxAudioPlayerModule } from "ngx-audio-player";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TrackTableComponent } from "src/app/components/track-table/track-table.component";
import { StepperComponent } from "../../components/stepper/stepper.component";
import { CdkStepperModule } from "@angular/cdk/stepper/";
import { CdkTreeModule } from "@angular/cdk/tree";
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BreadcrumbComponent } from "src/app/components/breadcrumb/breadcrumb.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { HeaderComponent } from "../../components/header/header.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { SectionTitleComponent } from "../../components/section-title/section-title.component";
import { MatDialogModule } from "@angular/material/dialog";
import { UrfTrackComponent } from "../../components/urf-track/urf-track.component";
import { PlayerComponent } from "../../components/player/player.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { MobileHeaderComponent } from "../../components/mobile-header/mobile-header.component";

@NgModule({
	declarations: [
		TrackTableComponent,
		StepperComponent,
		HeaderComponent,
		FooterComponent,
		BreadcrumbComponent,
		SectionTitleComponent,
		UrfTrackComponent,
		PlayerComponent,
		MobileHeaderComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		// @MM Fonts
		FontAwesomeModule,
		// @MM Ngx
		NgxPaginationModule,
		NgxAudioPlayerModule,
		BsDropdownModule,
		// @MM Mat CDK
		CdkTreeModule,
		CdkStepperModule,
		// @MM Mat
		MatSidenavModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatExpansionModule,
		MatTabsModule,
		MatCardModule,
		MatButtonModule,
		MatSelectModule,
		MatDialogModule,
		NgxSpinnerModule,
	],
	exports: [
		TrackTableComponent,
		FontAwesomeModule,
		NgxPaginationModule,
		NgxAudioPlayerModule,
		BsDropdownModule,
		CdkStepperModule,
		MatSidenavModule,
		MatIconModule,
		MatButtonModule,
		MatBottomSheetModule,
		CdkTreeModule,
		RouterModule,
		HeaderComponent,
		FooterComponent,
		StepperComponent,
		BreadcrumbComponent,
		MatExpansionModule,
		MatTabsModule,
		MatCardModule,
		MatSelectModule,
		SectionTitleComponent,
		UrfTrackComponent,
		PlayerComponent,
		NgxSpinnerModule,
		MobileHeaderComponent,
		MatButtonModule,
	],
})
export class ComponentsModule {}
