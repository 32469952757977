import {
	Component,
	Input,
	Directive,
	ElementRef,
	ViewChildren,
	QueryList,
} from "@angular/core";

import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { CdkStep, CdkStepper } from "@angular/cdk/stepper";

@Component({
	selector: "urf-stepper",
	templateUrl: "./stepper.component.html",
	styleUrls: ["./stepper.component.scss"],
	providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
	@ViewChildren("stepperLabel") stepperLabels: QueryList<ElementRef>;
	@Input("showLabels") public showLabels = true;

	public faExclamation = faExclamation;
	public faCheck = faCheck;

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.selectionChange.asObservable().subscribe((next) => {
			// console.log(next.selectedIndex);
			const label = this.stepperLabels.toArray()[next.selectedIndex];
			label.nativeElement.scrollIntoView({
				inline: "center",
				behavior: "smooth",
			});
			label.nativeElement.scrollIntoView();
		});
	}
	onClick(step: CdkStep): void {
		step.select();
	}

	log(stuff: any) {
		// console.log({ stuff });
	}
}
