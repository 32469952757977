import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EditProfileViewModel } from "src/app/viewModels/edit-profile.viewmodel";
import { EditProfileUseCase } from "src/app/useCases/edit-profile.usecase";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { touchAllFormFields } from "src/app/utils/validation";
import { MustMatch } from "src/app/utils/passwordMatch";
import { AngularFireAuth } from "@angular/fire/auth";
import { LogoutUsecase } from "src/app/useCases/logout.usecase";
import { CdkStepper } from "@angular/cdk/stepper";

@Component({
	selector: "app-edit-profile",
	templateUrl: "./edit-profile.component.html",
	styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
	@ViewChild("mobileStepper") mobileStepper: CdkStepper;

	editUserForm: FormGroup;
	userProfile: EditProfileViewModel;

	constructor(
		private editProfileUseCase: EditProfileUseCase,
		private logoutUseCase: LogoutUsecase,
		private formBuilder: FormBuilder,
		private spinner: NgxSpinnerService,
		private router: Router,
		private toast: ToastrService,
		private fireAuth: AngularFireAuth
	) {
		this.createFormBuild();
	}

	ngOnInit() {
		this.userProfile = this.editProfileUseCase.getSavedProfile();
		// console.log({ userProfile: this.userProfile });
		if (!this.userProfile) {
			return this.router.navigateByUrl("/view-profile");
		}
		this.editUserForm.setValue(this.setFormValues(this.userProfile));
		// this.editUserForm.controls.currentPassword.disable();
	}

	async updateProfile() {
		if (
			this.editUserForm.controls.password.pristine &&
			this.editUserForm.controls.confirmNewPassword.pristine
		) {
			this.editUserForm.controls.password.disable();
			this.editUserForm.controls.confirmNewPassword.disable();
		}
		if (!this.editUserForm.valid) {
			touchAllFormFields(this.editUserForm);
			return;
		}
		try {
			if (this.userProfile) {
				this.spinner.show();
				this.editProfileUseCase.updateProfile(
					this.editUserForm.value,
					(res) => {
						if (!res.code || res.data) {
							this.toast.success("Profile has been updated");
							this.spinner.hide();
							this.editUserForm.controls.password.enable();
							this.editUserForm.controls.confirmNewPassword.enable();
						} else {
							this.toast.error('Please try again or contact admin');
							this.spinner.hide();
						}
					}
				);
			} else {
			}
		} catch (error) {
			this.spinner.hide();
			this.toast.error(error);
		}
	}

	validateCurrentPasswordMobile() {
		let current = this.editUserForm.controls["currentPassword"];
		current.markAsTouched();
		if (current.valid) {
			const password = current.value;
			this.fireAuth.currentUser.then((user) => {
				this.fireAuth
					.signInWithEmailAndPassword(user.email, current.value)
					.then(
						() => {
							this.mobileStepper.next();
						},
						(err) => {
							this.toast.error("Incorrect password.");
							this.logoutUseCase.logout();
						}
					);
			});
		}
	}
	setFormValues(userProfile: EditProfileViewModel) {
		return {
			userId: userProfile.userId,
			name: userProfile.name,
			surname: userProfile.surname,
			email: userProfile.email,
			currentPassword: "",
			password: "",
			confirmNewPassword: "",
		};
	}

	private createFormBuild() {
		this.editUserForm = this.formBuilder.group(
			{
				userId: ["", Validators.required],
				name: ["", Validators.required],
				surname: ["", Validators.required],
				email: ["", [Validators.required, Validators.email]],
				currentPassword: ["", Validators.required],
				password: ["", [Validators.required, Validators.minLength(6)]],
				confirmNewPassword: ["", Validators.required],
			},
			{
				validator: MustMatch("password", "confirmNewPassword"),
			}
		);
	}
}
