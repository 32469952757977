import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browse-playlists',
  templateUrl: './browse-playlists.component.html',
  styleUrls: ['./browse-playlists.component.scss']
})
export class BrowsePlaylistsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
