import { Component, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { ModalService } from "src/app/utils/modal";
import { Observable } from "rxjs";

import { NavigationService } from "src/app/services/navigation/navigation.service";
import { UserContentService } from "src/app/services/user/content/user-content.service";
import { AuthService } from "src/app/services/security/auth-service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements AfterViewInit {
  @Output("hamburgerToggle") toggle = new EventEmitter();
  public cartCount$: Observable<number>;
  public hasMadePositivePaymentBefore: boolean = false;

  constructor(
    private modalService: ModalService,
    private userContentService: UserContentService,
    private navigationService: NavigationService,
  ) {
    this.cartCount$ = this.userContentService.cartCount$;
  }

  ngAfterViewInit() {}

  public cartToggle = () => {
    this.navigationService.desktopSidenav.toggle();
  };

  public isLoggedIn = () => {
    if (localStorage.getItem("user")) {
      return true;
    }

    this.userContentService.currentUserHasPayment.subscribe((resp) => {
      this.hasMadePositivePaymentBefore = resp;
    })

    return false;
  };

  public login = () => {
    const id = "login";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  };

  public signUp = () => {
    const id = "sign-up";
    this.modalService.add(id);
    this.modalService.open(id);
    return true;
  };


}
